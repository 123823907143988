import React from 'react';
import {Checkbox} from '@mui/material';

export const RowCheckbox = ({rowId, selectionModel, setSelectionModel}) => {
    const isSelected = selectionModel.includes(rowId);

    const handleChange = event => {
        if (event.target.checked) {
            // Add this row to the selection
            setSelectionModel(prev => [...prev, rowId]);
        } else {
            // Remove this row from the selection
            setSelectionModel(prev => prev.filter(id => id !== rowId));
        }
    };

    return (
        <Checkbox
            checked={isSelected}
            onChange={handleChange}
            size="small"
            style={{padding: 0}}
        />
    );
};
