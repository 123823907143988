import React, {Component} from 'react';
import {toggleCCT} from './actions';
import {connect} from 'react-redux';
import {
    Dialog,
    DialogContent
    // useMediaQuery
} from '@mui/material';
import moment from 'moment';
import Header from './Header';
import {DayMapping} from '../../common/constants';
import {WithSmallScreen} from '../../common/WithSmallScreen';
import Body from './Body';
import {Time} from '../../common/MomentTime';
import {isMeetingReadyToStart} from './utils';

class CCT extends Component {
    state = {
        anchor: null,
        isOpen: false
    };

    //Θ(1) open anchor
    dropAnchor = e => {
        this.setState({anchor: e.currentTarget, isOpen: true});
    };

    //Θ(1) Close anchor
    pullAnchor = () => {
        this.setState({anchor: null, isOpen: false});
    };

    //Θ(N) where N is length of current meetings
    //Filters the meetign which will start within next 30 mins
    getMeetingsToStart = meetings => {
        let meetingsToStart = meetings.filter(meeting => {
            return isMeetingReadyToStart(meeting);
        });
        return meetingsToStart;
    };

    //Θ(1) Returns the meeting start title
    getMeetingStartTitle = meeting => {
        const timeFormat = 'h:mm A';
        let startTime = Time.University(meeting.start_time).format(timeFormat);
        let endTime = Time.University(meeting.start_time)
            .add(meeting.duration, 'minutes')
            .format(timeFormat);
        return startTime + ' - ' + endTime + ' - ' + meeting.topic;
    };

    getSchedule = course => {
        const schedule = course?.SCHEDULE;

        if (schedule) {
            return schedule.map(entry => {
                if (entry?.MEETING_DAYS) {
                    let weekdays = [
                        ...entry?.MEETING_DAYS.replace('<BR>', '')
                    ]?.reduce((weekday, shortWeekDay, index, array) => {
                        if (index === 0) weekday += DayMapping[shortWeekDay];
                        else if (index === array.length - 1)
                            weekday += ' and ' + DayMapping[shortWeekDay];
                        else weekday += ', ' + DayMapping[shortWeekDay];

                        return weekday;
                    }, '');

                    const timeFormat = 'h:mm A';

                    let startTime = Time.Local(
                        course?.START_DATE + 'T' + entry?.BEGIN_TIME
                    ).format(timeFormat);
                    let endTime = Time.Local(
                        course?.START_DATE + 'T' + entry?.END_TIME
                    ).format(timeFormat);

                    let details = {
                        weekdays,
                        startTime,
                        endTime
                    };

                    return details;
                }

                return {};
            });
        }

        return [];
    };

    render() {
        const subjectCode = this.props.course.SUBJECT_CODE
            ? this.props.course.SUBJECT_CODE
            : this.props.course.SUBJECT_5;
        const courseNumber = this.props.course.COURSE_NUMBER
            ? this.props.course.COURSE_NUMBER
            : this.props.course.COURSE_NUMBER_5;
        const courseTitle = this.props.course.COURSE_TITLE
            ? this.props.course.COURSE_TITLE
            : this.props.course.COURSE_TITLE_5;
        const crns = this.props.course.CRNS
            ? this.props.course.CRNS
            : this.props.course.CRN;
        const termCode = this.props.course.SSBSECT_TERM_CODE;
        const isTeaching = this.props.course?.isTeaching;
        const isTaking = this.props.course?.isTaking;

        let meetingsToStart = [];
        meetingsToStart = this.getMeetingsToStart(this.props.currentMeetings);

        let schedule = this.getSchedule(this?.props?.course);

        return (
            <React.Fragment>
                <Dialog
                    id="cct__dialog"
                    open={this.props.isCCT}
                    maxWidth="xl"
                    fullWidth
                    fullScreen={this.props.isSmall}
                    onClose={() => this.props.toggleCCT({})}
                    className="myOdu__cct cctModal"
                >
                    <Header
                        subjectCode={subjectCode}
                        courseNumber={courseNumber}
                        courseTitle={courseTitle}
                        termCode={termCode}
                        //TODO: pass schedule
                        schedule={schedule}
                        crns={crns}
                        meetingsToStart={meetingsToStart}
                        anchor={this.state.anchor}
                        dropAnchor={this.dropAnchor}
                        pullAnchor={this.pullAnchor}
                        isOpen={this.state.isOpen}
                        getMeetingStartTitle={this.getMeetingStartTitle}
                        isTaking={isTaking}
                        isTeaching={isTeaching}
                        onClose={this.props.toggleCCT}
                    />

                    <DialogContent sx={{p: 0, pb: 1}}>
                        <Body />
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        isCCT: state.CCTReducer.isCCT,
        course: state.CCTReducer.course,
        currentMeetings: state.CCTReducer.meetings.currentMeetings,
        pastMeetings: state.CCTReducer.meetings.pastMeetings
    };
};

const mapDispatchToProps = dispatch => ({
    toggleCCT: course => dispatch(toggleCCT(course))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WithSmallScreen(CCT));
