import React, {useState} from 'react';
import {Menu, MenuItem, IconButton} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEllipsis} from '@fortawesome/pro-regular-svg-icons';

export const RowEdit = ({rowId, actions = []}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const isMenuOpen = Boolean(anchorEl);

    return (
        <>
            <IconButton
                onClick={handleOpen}
                className={`myOdu__iconButton small cell  ${
                    isMenuOpen ? 'rowEditIcon--active' : 'rowEditIcon'
                }`}
            >
                <FontAwesomeIcon icon={faEllipsis} size="xs" />
            </IconButton>

            <Menu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
            >
                {actions.length &&
                    actions.map(action => (
                        <MenuItem
                            onClick={() => {
                                action.handler(rowId);
                                handleClose();
                            }}
                            disabled={action.disabled}
                        >
                            {action.title}
                        </MenuItem>
                    ))}
            </Menu>
        </>
    );
};
