import React, {useEffect, useState} from 'react';
import PortalModal from '../common/PortalModal';
import { Stack, Typography } from '@mui/material';

const ConfirmationDialog = ({isOpen, message = '', title = '', onConfirm, onClose, primaryButtonTitle, secondaryButtonTitle}) => {
    return (
        <PortalModal
            isOpen = {isOpen}
            onClose = {onClose}
            title = {title}
            maxWidth="sm"
            primaryButtonTitle={primaryButtonTitle}
            onPrimaryClick = {onConfirm}
            secondaryButtonTitle={secondaryButtonTitle}
            onSecondaryClick = {onClose}
            isCloseable
        >
            <Stack direction={'column'}>
                {
                    message.split('\n').map((line, index) => (
                        <div>
                            <Typography variant="p" key={index}>{line}</Typography>
                            <br />
                        </div>
                    ))
                }
            </Stack>
        </PortalModal>
    );

}

export default ConfirmationDialog;