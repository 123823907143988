import moment from 'moment-timezone';
import {TIME_ZONE} from './constants';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(timezone);
dayjs.extend(utc);

export const Time = {
    University: (time, isDayJS = false) => {
        const lib = isDayJS ? dayjs : moment;
        return time ? lib(time).tz(TIME_ZONE) : lib().tz(TIME_ZONE);
    },
    Local: (time = undefined) => (time && moment(time)) || moment()
};

// export const UniversityTime = {
//     get: (time = undefined) => time && moment(time).tz(TIME_ZONE) || moment().tz(TIME_ZONE)
// }

// export const LocalTime = {
//     get: (time = undefined) => time && moment(time) || moment()
// }
