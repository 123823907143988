import {useState, useEffect} from 'react';
import {getData} from '../../DataAccessLayer';
import {ssa} from '../../DataAccessLayer/services';
import {
    cOrAboveGrades,
    statusOptions,
    statusesRequireReason,
    reasonsRequireComment,
    reasonsRequireGrade
} from './options';
import {isProduction} from '../Layout/utils';

export const useCourseAccess = (termCode, crn) => {
    const [courseAccess, setCourseAccess] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const isTestingAllowed = !isProduction() && courseAccess.isSSASuperUser;

    useEffect(() => {
        const verifyAccess = async () => {
            try {
                const data = await getData(ssa.access({termCode, crn}), true);
                setCourseAccess(data);
            } catch (err) {
                console.error('Error verifying access:', err);
            } finally {
                setIsLoading(false);
            }
        };

        verifyAccess();
    }, [termCode, crn]);

    return {courseAccess, isLoading, isTestingAllowed};
};

export const pollingStatus = async (fetchFn, validate, interval = 2000) => {
    while (true) {
        try {
            const result = await fetchFn();

            if (validate(result)) {
                return result;
            }
        } catch (err) {}

        await new Promise(resolve => setTimeout(resolve, interval));
    }
};

// Pure validation functions that return error details
export const validateGrade = ({reasonValue, gradeValue}) => {
    if (reasonsRequireGrade.includes(reasonValue)) {
        if (!gradeValue || gradeValue === '-') {
            return {
                required: `Grade is required when Explanation/Reason is '${reasonValue}'`,
                errorTextShortened: 'Required'
            };
        } else if (cOrAboveGrades.includes(gradeValue)) {
            return {
                required: 'Grade must be C- or below',
                errorTextShortened: 'Error'
            };
        }
    }
    return {required: '', errorTextShortened: ''};
};

export const validateComment = ({commentValue, reasonValue}) => {
    if (!commentValue && reasonsRequireComment.includes(reasonValue)) {
        return {
            required: `Comment is required when Explanation/Reason is '${reasonValue}'`,
            errorTextShortened: ''
        };
    }
    return {required: '', errorTextShortened: ''};
};

export const validateReason = ({
    statusValue,
    reasonValue,
    isChangedRow,
    isClickedCell,
    reasonOptions
}) => {
    if (!reasonValue || reasonValue === '-') {
        if (statusesRequireReason.includes(statusValue)) {
            return {
                required: `Required when status is '${statusValue}'`,
                errorTextShortened: 'Required'
            };
        } else if (isClickedCell && (!statusValue || statusValue === '-')) {
            return {
                required:
                    'Status required before Explanation/Reason can be chosen',
                errorTextShortened: 'Status required first'
            };
        }
    } else if (
        !reasonOptions.find(option => option.value === reasonValue) &&
        isChangedRow
    ) {
        return {
            required:
                'Current value is now invalid. Please select from the available options.',
            errorTextShortened: 'Error'
        };
    }
    return {required: '', errorTextShortened: ''};
};

export const validateStatus = ({statusValue, isChangedRow}) => {
    if (isChangedRow) {
        if (!statusValue || statusValue === '-') {
            return {
                required: 'Status is required when record is changed',
                errorTextShortened: 'Required'
            };
        } else if (
            !statusOptions.find(option => option.value === statusValue)
        ) {
            return {
                required:
                    'Current value is now invalid. Please select from the available options.',
                errorTextShortened: 'Error'
            };
        }
    }
    return {required: '', errorTextShortened: ''};
};
