import React, {useEffect, useState} from 'react';
import {Box, Typography, Paper, Grid} from '@mui/material';
import {FrontendErrorLogTable} from './FrontendErrorLogTable';
import getColumns from './columns';
import {errors} from '../../../DataAccessLayer/services';
import {getData} from '../../../DataAccessLayer';
import {FrontendErrorLogDetails} from './FrontendErrorLogDetails';
import {GridToolbar} from '@mui/x-data-grid-pro';
import {MasterDetails} from '../MasterDetails';

export const FrontEndErrorLog = () => {
    // Aggregated error log table
    const [errorLogData, setErrorLogData] = useState([]);
    const [errorLogMap, setErrorLogMap] = useState({});
    const [isErrorLogLoading, setIsErrorLoading] = useState(false);
    const [selectedErrorLog, setSelectedErrorLog] = useState(null);

    // By Midas error log table
    const [midasErrorLogData, setMidasErrorLogData] = useState([]);
    const [midasErrorLogMap, setMidasErrorLogMap] = useState({});
    const [selectedMidasErrorLog, setSelectedMidasErrorLog] = useState(null);
    const [isMidasErrorLogLoading, setIsMidasErrorLoading] = useState(false);

    const [midasSearch, setMidasSearch] = useState('');

    useEffect(() => {
        loadAllErrorLog();
    }, []);

    const loadAllErrorLog = async () => {
        setIsErrorLoading(true);
        try {
            const data = await getData(errors.getAll, true);

            // Create a mapping of Error Log data for quick lookup
            const errorLogMap = data.reduce((acc, item) => {
                acc[item.id] = item;
                return acc;
            }, {});

            setErrorLogData(data);
            setErrorLogMap(errorLogMap);
        } catch (err) {
            console.error('Failed to load Error Logs:', err);
        } finally {
            setIsErrorLoading(false);
        }
    };

    const loadAllErrorLogByMidas = async () => {
        setIsMidasErrorLoading(true);
        try {
            const data = await getData(errors.getAllByMidas(midasSearch), true);

            // // Create a mapping of Midas Error Log data for quick lookup
            const midasErrorLogMap = data.reduce((acc, item) => {
                acc[item.MIDAS + item.CREATED_AT] = item;
                return acc;
            }, {});

            setMidasErrorLogData(data);
            setMidasErrorLogMap(midasErrorLogMap);
        } catch (err) {
            console.error('Failed to load Error Logs:', err);
        } finally {
            setIsMidasErrorLoading(false);
        }
    };

    const getSelectedError = async ({row}) => {
        const {id, error, lastSeen, count} = row;
        const data = errorLogMap[id];

        if (data) {
            setSelectedErrorLog({
                id,
                lastSeen,
                error,
                count
            });
        }
    };

    const getSelectedMidasError = async ({row}) => {
        const {CREATED_AT: createdAt, MIDAS: midas, ERROR: error} = row;
        const data = midasErrorLogMap[midas + createdAt];

        if (data) {
            setSelectedMidasErrorLog({
                midas,
                lastSeen: createdAt,
                error
            });
        }
    };

    const handleMidasSearch = event => {
        if (event.key === 'Enter' && midasSearch) {
            loadAllErrorLogByMidas();
        }
    };

    return (
        <MasterDetails
            id="FEErrorLog"
            pageTitle={'Frontend Error Log'}
            className="frontEndErrorLog"
            tables={[
                {
                    id: 'aggregated_results',
                    title: 'Aggregated Results',
                    master: (
                        <FrontendErrorLogTable
                            className="myOdu__table tableSection"
                            rows={errorLogData}
                            columns={getColumns('aggregated')}
                            isLoading={isErrorLogLoading}
                            onClick={getSelectedError}
                            slots={{toolbar: GridToolbar}}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    csvOptions: {disableToolbarButton: true},
                                    printOptions: {disableToolbarButton: true},
                                    quickFilterProps: {
                                        sx: {m: 1, width: '100%'},
                                        variant: 'outlined',
                                        size: 'small',
                                        placeholder: 'Search Error Log'
                                    }
                                }
                            }}
                            disableColumnFilter
                            disableColumnSelector
                            disableDensitySelector
                        />
                    ),

                    detail: <FrontendErrorLogDetails data={selectedErrorLog} />
                },
                {
                    id: 'midasid_search',
                    title: 'MIDAS ID Search',
                    master: (
                        <FrontendErrorLogTable
                            className="myOdu__table tableSection"
                            rows={midasErrorLogData}
                            columns={getColumns()}
                            isLoading={isMidasErrorLogLoading}
                            onClick={getSelectedMidasError}
                            slots={{toolbar: GridToolbar}}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    csvOptions: {disableToolbarButton: true},
                                    printOptions: {disableToolbarButton: true},
                                    quickFilterProps: {
                                        value: midasSearch,
                                        onChange: e =>
                                            setMidasSearch(e.target.value),
                                        sx: {m: 1, width: '100%'},
                                        variant: 'outlined',
                                        size: 'small',
                                        placeholder: 'Search Midas',
                                        onKeyDown: handleMidasSearch
                                    }
                                }
                            }}
                            disableColumnFilter
                            disableColumnSelector
                            disableDensitySelector
                        />
                    ),
                    detail: (
                        <FrontendErrorLogDetails data={selectedMidasErrorLog} />
                    )
                }
            ]}
        />
    );
};
