export const statusOptions = [
    {label: '-', value: '-'},
    {
        label: 'Satisfactory',
        value: 'Satisfactory'
    },
    {
        label: 'Satisfactory - Needs Improvement',
        value: 'Satisfactory - Needs Improvement'
    },
    {
        label: 'Unsatisfactory',
        value: 'Unsatisfactory'
    }
];

export const getReasonOptions = () => {
    const baseOptions = [{label: '-', value: '-'}];
    const otherOptions = [
        {
            label: 'Other',
            value: 'Other'
        }
    ];
    const satisfactoryReasons = [
        {
            label: 'Regular attendance',
            value: 'Regular attendance'
        },
        {
            label: 'Consistent effort/participation',
            value: 'Consistent effort/participation'
        },
        {
            label: 'Good work quality/comprehension',
            value: 'Good work quality/comprehension'
        },
        {
            label: 'Completes Assignments',
            value: 'Completes Assignments'
        },

        {
            label: 'Noticeable improvements',
            value: 'Noticeable improvements'
        }
    ];

    const needsImprovementReasons = [
        {
            label: 'Excessive absences',
            value: 'Excessive absences'
        },
        {
            label: 'Excessive tardiness',
            value: 'Excessive tardiness'
        },
        {
            label: 'Missing/Late assignments',
            value: 'Missing/Late assignments'
        },
        {
            label: 'Needs general study skills assistance',
            value: 'Needs general study skills assistance'
        },
        {
            label: 'Poor assignment/test grade/participation',
            value: 'Poor assignment/test grade/participation'
        },
        {
            label: 'C- or below',
            value: 'C- or below'
        }
    ];

    const unsatisfactoryReasons = [
        {
            label: 'Never attended',
            value: 'Never attended'
        },
        {
            label: 'At risk to fail course',
            value: 'At risk to fail course'
        }
    ];

    return {
        '-': [...baseOptions],
        // Excellent is now Satisfactory. Keep this for backward compatibility.
        excellent: [...baseOptions, ...satisfactoryReasons, ...otherOptions],
        satisfactory: [...baseOptions, ...satisfactoryReasons, ...otherOptions],
        'satisfactory - needs improvement': [
            ...baseOptions,
            ...needsImprovementReasons,
            ...otherOptions
        ],
        unsatisfactory: [
            ...baseOptions,
            ...unsatisfactoryReasons,
            ...needsImprovementReasons,
            ...otherOptions
        ]
    };
};

export const gradeOptions = [
    {label: '-', value: '-'},
    {
        label: 'A',
        value: 'A'
    },
    {
        label: 'A-',
        value: 'A-'
    },
    {
        label: 'B+',
        value: 'B+'
    },
    {
        label: 'B',
        value: 'B'
    },
    {
        label: 'B-',
        value: 'B-'
    },
    {
        label: 'C+',
        value: 'C+'
    },
    {
        label: 'C',
        value: 'C'
    },
    {
        label: 'C-',
        value: 'C-'
    },
    {
        label: 'D+',
        value: 'D+'
    },
    {
        label: 'D',
        value: 'D'
    },
    {
        label: 'D-',
        value: 'D-'
    },
    {
        label: 'F',
        value: 'F'
    },
    {
        label: 'P',
        value: 'P'
    }
];

export const cOrAboveGrades = ['A', 'A-', 'B+', 'B-', 'B', 'C+', 'C'];
export const statusesRequireReason = [
    'Unsatisfactory',
    'Satisfactory - Needs Improvement'
];
export const reasonsRequireComment = ['Other', 'At risk to fail course'];
export const reasonsRequireGrade = ['C- or below', 'At risk to fail course'];
