import _ from 'lodash';

//θ(1) Returns all the columns of the datagrid
export const getColumns = ({termCodes}) => [
    {
        field: 'LASTNAME',
        headerName: 'Last Name',
        width: 150,
        valueGetter: (value, row) => {
            return row.lastName;
        }
    },
    {
        field: 'firstName',
        headerName: 'First Name',
        width: 150
    },
    {
        field: 'maxAssignents',
        headerName: 'Max Assignments',
        width: 150
    },
    {
        field: 'primaryCount',
        headerName: 'Primary Count',
        width: 150
    },
    {
        field: 'secondaryCount',
        headerName: 'Secondary Count',
        width: 150
    },
    {
        field: 'totalCount',
        headerName: 'Total',
        width: 100
    },
    {
        field: 'percentage',
        headerName: 'Percentage',
        width: 100
    },
    {
        field: 'gradCountCurrentTerm',
        headerName: `Graduating ${_.capitalize(
            termCodes?.CURRENT_TERM?.title
        )}`,
        width: 200
    },
    {
        field: 'gradCountNextTerm',
        headerName: `Graduating ${_.capitalize(termCodes?.NEXT_TERM?.title)}`,
        width: 200
    }
];
