import React from 'react';
import {
    Typography,
    Breadcrumbs,
    Link,
    Button,
    Box,
    Stack,
    Alert
} from '@mui/material';
import {useLocation, Link as RouterLink, useParams} from 'react-router-dom';
import {getPageDetails} from './config';
import {useSelector} from 'react-redux';
import _ from 'lodash';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCommentPen} from '@fortawesome/pro-regular-svg-icons';
import {isUserAdmin} from '../Layout/utils';

export const PageHeader = ({
    course = {},
    actions = [],
    isTestingAllowed = false
}) => {
    const params = useParams();
    const location = useLocation();

    const paths = location.pathname.split('/').filter(Boolean);
    const terms = useSelector(state => state.termsReducer?.terms ?? {});

    // Extract termCode, crn, and page dynamically
    const {termCode, crn} = params;
    const [base, page] = paths ?? [];

    const term = _.capitalize(
        Object.values(terms).find(item => item.code === termCode)?.title ?? ''
    );

    const trueUser = useSelector(state => state.AWSReducer.user);
    const isAdmin = isUserAdmin(trueUser);

    const isCourseActive = Boolean(terms.CURRENT_TERM?.code === termCode);

    const {from = '/'} = {};

    // Extract course details with default values
    const {
        SUBJECT_CODE: subjectCode = course?.SUBJECT_5 || '',
        COURSE_NUMBER: courseNumber = course?.COURSE_NUMBER_5 || '',
        COURSE_TITLE: courseTitle = course?.COURSE_TITLE_5 || '',
        CRNS
    } = course;

    const crns = CRNS.join(', ') || '';

    const courseHeading = `${subjectCode} ${courseNumber} - ${courseTitle}`;

    const subPage = getPageDetails({termCode, crn})[page.toLowerCase()];
    const basePage = getPageDetails({termCode, crn})[base.toLowerCase()];

    // Course base URL
    const {page: basePath, title: baseTitle} = basePage;

    // Page title mapping
    const {title: pageTitle} = subPage ?? basePage;

    return (
        <>
            {!isCourseActive && (
                <Alert
                    icon={<FontAwesomeIcon icon={faCommentPen} />}
                    className="myOdu__alert banner"
                    sx={{mb: 2}}
                >
                    {isTestingAllowed
                        ? 'Editing an archived class is enabled because it is in a testing environment'
                        : isAdmin
                        ? 'Editing an archived class is enabled because user is admin.'
                        : 'Editing is disabled as it is available to current semester only.'}
                </Alert>
            )}
            <Stack
                direction={{md: 'column', lg: 'row'}}
                justifyContent="space-between"
            >
                <Box>
                    <Typography component="h2">{pageTitle}</Typography>

                    <Breadcrumbs
                        className="myOdu__breadcrumbs"
                        id="studentAlerts__breadCrumbs"
                        aria-label="breadcrumb"
                    >
                        <Link component={RouterLink} to={from}>
                            {from === '/'
                                ? 'Dashboard'
                                : 'Course Collaboration Tools'}
                        </Link>

                        {subPage && (
                            <Link
                                component={RouterLink}
                                to={basePath}
                                state={location.state}
                            >
                                {baseTitle}
                            </Link>
                        )}

                        <Typography>{pageTitle}</Typography>
                    </Breadcrumbs>

                    <Typography component="h3">{courseHeading}</Typography>
                    <Typography>{term}</Typography>
                    <Typography variant="caption">CRNs - {crns}</Typography>
                </Box>
                {actions.length > 0 && (
                    <Stack direction="row" spacing={2} alignItems="center">
                        {actions.map(action => (
                            <Button
                                variant="outlined"
                                className="myOdu__button secondary"
                                size="small"
                                onClick={action.handler}
                            >
                                {action.title}
                            </Button>
                        ))}
                    </Stack>
                )}
            </Stack>
        </>
    );
};
