import React, {useState, useEffect} from 'react';
import {Checkbox, Menu, MenuItem} from '@mui/material';
import {faAngleDown} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export const HeaderMenu = ({
    selectionModel,
    setSelectionModel,
    allRowIds,
    getChangedRowIds,
    getUnchangedRowIds
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [isChecked, setIsChecked] = useState(false);

    // Sync checkbox state with selection model
    useEffect(() => {
        setIsChecked(
            selectionModel.length > 0 &&
                selectionModel.length === allRowIds.length
        );
    }, [selectionModel, allRowIds]);

    const handleOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelectAll = () => {
        setSelectionModel(allRowIds); // Select all rows
        setIsChecked(true);
        handleClose();
    };

    const handleClearSelection = () => {
        setSelectionModel([]); // Clear all selection
        setIsChecked(false);
        handleClose();
    };

    const handleChangedRows = () => {
        const changedRowIds = getChangedRowIds();
        setSelectionModel(changedRowIds); // Select only changed rows
        setIsChecked(false); // Changed rows may not cover all rows
        handleClose();
    };

    const handleUnchangedRows = () => {
        const unchangedRowIds = getUnchangedRowIds();
        setSelectionModel(unchangedRowIds); // Select only unchanged rows
        setIsChecked(false); // Unchanged rows may not cover all rows
        handleClose();
    };

    return (
        <>
            <Checkbox
                size="small"
                checked={isChecked}
                indeterminate={
                    selectionModel.length > 0 &&
                    selectionModel.length < allRowIds.length
                }
                onChange={e => {
                    if (e.target.checked) {
                        handleSelectAll();
                    } else {
                        handleClearSelection();
                    }
                }}
            />
            <FontAwesomeIcon
                icon={faAngleDown}
                onClick={handleOpen}
                aria-haspopup="true"
                aria-expanded={Boolean(anchorEl)}
                tabIndex="0"
                role="button"
            />
            <Menu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
            >
                <MenuItem onClick={handleSelectAll}>Select All</MenuItem>
                <MenuItem onClick={handleClearSelection}>None</MenuItem>
                <MenuItem onClick={handleChangedRows}>Changed Rows</MenuItem>
                <MenuItem onClick={handleUnchangedRows}>
                    Unchanged Rows
                </MenuItem>
            </Menu>
        </>
    );
};
