import { result } from "lodash";
import { isUserAdmin } from "../Layout/utils";
import { AppConfigs, DefaultWidgetOrder, WidgetConfigs } from "./config"
import { DEFAULT_ROLE } from "../common/constants";
import { deepClone } from "@mui/x-data-grid/utils/utils";

//Θ(1) Returns the widget object with correct set of attributes
export const buildWidgetPref = (widgetId, isDisplay = false, isExpand = false) => {
    return {
        id: widgetId,
        isDisplay,
        isExpand
    }
}

//Θ(N) where N is the number of widgets
//Returns a default widget order with correct set of parameters in individual widget object.
export const getDefaultWidgetOrder = () => {
    let widgetOrder = [];
    WidgetConfigs.Widgets.forEach(section => {

        //Filter out the deleted widgets
        //For every widget create a pref widget object
        const sectionOrder = section.filter(sectionWidget => !sectionWidget.isDeleted).map(sectionWidget => {
            return buildWidgetPref(sectionWidget.id, true, true);
        });

        widgetOrder.push(sectionOrder);
    });

    return widgetOrder;
}

export const isSameWidgetsVersion = (widgets) => {
    if (widgets.Version === WidgetConfigs.Version)
        return true
    
    return false;
}

//Θ(N) where N is the number of widgets in widget configs
//It updates the widget preferences whenever there is a change in widgets config
export const updateWidgetsOrder = (widgets, user) => {

    //This code is to update the preferences widget structure
    // if(WidgetConfigs.Version === 1.6)
    widgets = updateWidgetPreferences(widgets)

    let widgetsOrder = deepClone(widgets.WidgetsOrder);

    WidgetConfigs.Widgets.forEach((section, index) => {

        section.forEach(widget => {
            //For every widget in config
            //Match the widget with the id in users prefences
            const isWidgetInPref = widgetsOrder[index].some(prefWidget => prefWidget.id === widget.id);

            if(isWidgetInPref && widget.isDeleted) {
                //Delete widget from the array if widget is in users pref and has been deleted from config
                const widgetIndex = widgetsOrder[index].findIndex(prefWidget => prefWidget.id === widget.id);
                widgetsOrder[index].splice(widgetIndex, 1);
            }
            else if (!isWidgetInPref && !widget.isDeleted) {
                //Add to the array if the widget is newly added and not in users pref
                const isDisplay = isDisplayWidget(widget, user)
                //If no drag then add to the top of the section
                if(widget.isNoDrag) {
                    widgetsOrder[index].splice(0, 0, buildWidgetPref(widget.id, isDisplay, true));
                } 
                else {
                    //Add to the bottom of the section
                    widgetsOrder[index].push(buildWidgetPref(widget.id, isDisplay, true));
                }
            }
        });
    });

    widgets.WidgetsOrder = widgetsOrder;
    widgets.Version = WidgetConfigs.Version;

    return widgets
}

//Θ(NxM) where N is the number of widgets in a section
//M is the number of widgets in a widgets config
//This function is to update the old widget structure to widget structure introduced in 1.6
const updateWidgetPreferences = (widgets) => {
    //Flatten the array to get a single array of all widgets
    //Check the type of value for first element in the array
    //If its string then the preference object needs to be updated.
    if(typeof widgets.WidgetsOrder.flat()[0] === 'string') {
        widgets.WidgetsOrder = widgets.WidgetsOrder.map((section, sectionIndex) => {
            return section.map(widgetId => {
                const widget = WidgetConfigs.Widgets[sectionIndex].find(configWidget => configWidget.id === widgetId);
                if(widget)
                    return buildWidgetPref(widget.id, true, true);
            }).filter(widget => widget !== undefined)
        });
    }

    return widgets;
}

export const isSameAppsVersion = (apps) => {
    if(apps.Version === AppConfigs.Version)
        return true;

    return false;
}

export const updateApps = (preferences, user) => {
    
    let selectedAppsCount = getSelectedAppsCount(preferences.Apps);

    AppConfigs.Apps.forEach((app, idx) => {
        //Check if app is deleted
        if(app.isDeleted && (app.id in preferences.Apps)) {
            preferences = removeApp(preferences, app.id);
            delete preferences.Apps[app.id];
        }

        //Add New Apps
        if(!app.isDeleted && !(app.id in preferences.Apps)) {
            const result = addAppBasedOnRole(preferences, app, user, selectedAppsCount);
            preferences = result.preferences;
            selectedAppsCount = result.selectedAppsCount;
        }
    })

    preferences.Version = AppConfigs.Version;

    return preferences;
}

const addAppBasedOnRole = (preferences, app, user, selectedAppsCount) => {
    if(!(app.id in (preferences?.Apps ?? {}))) {
        if(app.isDefaultSelected || app?.preSelected?.role?.some((item) => item in (user.roles ?? {}))) {
            preferences['Apps'] = {...(preferences?.Apps ?? {}), [app.id]: (selectedAppsCount)}
            selectedAppsCount++;
        } else {
            preferences['Apps'] = {...(preferences?.Apps ?? {}), [app.id]: -1}
        }
    }
    return {preferences, selectedAppsCount};
}

export const removeApp = (apps, appId) => {
    Object.keys(apps.Apps).forEach(app => {
        //Re-arrange the sequence of rest of apps
        if(apps.Apps[app] > apps.Apps[appId]) {
            apps.Apps[app] = (apps.Apps[app] - 1)
        }
    });

    apps.Apps[appId] = -1;
    
    return apps;
}

//Θ(N) where N is the number of roles
//Checks if the widget has to be displayed to the user based on user's role
export const isDisplayWidget = (widget, user) => {
    const roles = user?.roles ?? DEFAULT_ROLE
    if((!widget.defaultDisplay || isUserAdmin(user)) && !widget?.isDeleted ) {
        return true
    }
    else if(widget.defaultDisplay?.role) {
        //Check if the user has the required role
        return widget?.defaultDisplay?.role?.some(role => {
            return ((role in roles) && !widget?.isDeleted)
        });
    } else if (widget?.isDeleted) {
        return false;
    }
    return true;
}

//Θ(N) where N is the number of roles
//Checks if the users role matches the widgets required role
export const verifyWidgetRole = (widget, user) => {
    const roles = user?.roles ?? DEFAULT_ROLE
    return widget?.defaultDisplay?.role?.some(role => {
        return ((role in roles) && !widget?.isDeleted)
    });
}

//Θ(NxM) where N is the size of widgets and M is the size of user roles
//Returns the list of widget along with config filtered on user roles
export const getWidgetsBasedOnRole = (widgets, user) => {
    const filteredWidgets = widgets.map(section => {

        return section.filter(widget => {
            //Keep the widget if its being shown for all
            return isDisplayWidget(widget, user);
        })
    });
    
    return filteredWidgets;
}

//Θ(NxM) where N is the size of sections on dashboard and M is the size of widgets in section
//Returns the widget order filtered by role
export const getWidgetOrderBasedOnRole = (widgetOrder, user) => {
    //Fetch the list of widgets filtered on role
    let roleBasedWidgets = getWidgetsBasedOnRole(WidgetConfigs.Widgets, user);

    //Create an object of widget ids which has to be shown
    let roleBasedWidgetsObject = {};
    roleBasedWidgets?.forEach((section, index) => {
        roleBasedWidgetsObject = section.reduce((widgetObject, widget) => {
            return {
                ...widgetObject,
                [widget.id]: index
            }
        }, roleBasedWidgetsObject);
    });

    //If the widget is present in roleBasedWidgets object then keep widgets isDisplay true else false
    widgetOrder = widgetOrder.map(section => {
        const filteredSection = section.map((widget) => {
            if(widget.id in roleBasedWidgetsObject) {
                delete roleBasedWidgetsObject[widget.id];
                return buildWidgetPref(widget.id, true, true);
            }
            return buildWidgetPref(widget.id, false, false)
        })
        return filteredSection;
    });

    return widgetOrder;
}

//Θ(N) where N is the number of application in the object
//returns the number of selected application in the list of applications.
export const getSelectedAppsCount = (apps) => {
    let selectedAppsCount = Object.entries(apps).reduce((accumulator, [appId, appIndex]) => {

        //Convert old app format to new
        //TODO remove this block of code when all users are migrated to new config structure.
        if(typeof appIndex === 'boolean') {
            apps[appId] = accumulator;
        }

        if(appIndex > -1)
            return accumulator + 1;

        return accumulator;
    }, 0);

    return selectedAppsCount;
}

//Θ(N) where N is the number of applications 
//Updates the selection of the app
export const toggleAppSelection = (apps, app, selectedAppsCount) => {
    if (apps[app.id] > -1) {
        apps = removeApp(apps, app.id)
        selectedAppsCount = selectedAppsCount - 1;
    } else {
        let index = selectedAppsCount;
        apps[app.id] = index++;
        selectedAppsCount = index;
    }
    return {
        apps, selectedAppsCount
    }
}

//Θ(N) where N is the number of widgets 
//Updates the selection of the widget
export const toggleWidgetSelection = (widgetsOrder, widgetId) => {

    //Get teh widget from the mentioned section
    const selectedWidget = widgetsOrder?.flat()?.find(orderedWidget => orderedWidget.id === widgetId);

    //Toggle isDisplay
    if(selectedWidget) {
        selectedWidget.isDisplay = !selectedWidget.isDisplay;
    }

    return widgetsOrder;
}