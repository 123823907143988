import {
    Box,
    FormGroup,
    Button,
    Stack,
    Chip,
    Typography,
    Grid,
    IconButton
} from '@mui/material';
import dayjs from 'dayjs';
import React, {Component} from 'react';
import {Col, Row} from 'reactstrap';
import {
    MeetingDateFormat,
    MeetingTimeFormat,
    OptionsMapping,
    RecurringOptions
} from './config';
import RepeatMeetings from './RepeatMeetings';
import {connect} from 'react-redux';
import {postData} from '../../../../../../DataAccessLayer';
import {adhoc} from '../../../../../../DataAccessLayer/services';
import {toast} from 'react-toastify';
import {ZoomTabMapping} from '../config';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleXmark} from '@fortawesome/pro-light-svg-icons';
import PortalTooltip from '../../../../../common/PortalTooltip';
import {FormTextField} from '../../../../../common/Form/FormTextField';
import {FormDropDown} from '../../../../../common/Form/FormDropdown';
import {FormDatePicker} from '../../../../../common/Form/FormDatePicker';
import {FormTimePicker} from '../../../../../common/Form/FormTimePicker';
import {FormCheckbox} from '../../../../../common/Form/FormCheckbox';
import {FormButton} from '../../../../../common/Form/FormButton';
import {getUserEmail} from './utils';
import {WithSmallScreen} from '../../../../../common/WithSmallScreen';
import {Time} from '../../../../../common/MomentTime';

class Adhoc extends Component {
    state = {
        isLoading: false,
        isShareError: false,
        meetingTitle: '',
        recurrence: RecurringOptions.noRepeat.value,
        termStartDate: '',
        termEndDate: '',

        [OptionsMapping.isRecordMeeting]: false,
        [OptionsMapping.isPublishToCanvas]: false,
        [OptionsMapping.isJoinBeforeHost]: false,
        [OptionsMapping.isEnableWaitingRoom]: false,
        [OptionsMapping.isHideMeeting]: false,
        [OptionsMapping.isShare]: false,
        [OptionsMapping.meetingStartTime]: Time.University(null, true),
        [OptionsMapping.meetingEndTime]: Time.University(null, true),

        otherClasses: [],
        meetingDates: [
            Time.University().diff(
                Time.University(this.props.course.START_DATE),
                'days'
            ) < 0
                ? dayjs(this.props.course.START_DATE)
                : Time.University(null, true)
        ],

        recurring: {
            endDate: RecurringOptions.endDateBy, //End Date type under Recurrence
            byDate:
                Time.University().diff(
                    Time.University(this.props.course.END_DATE),
                    'days'
                ) < 0
                    ? dayjs(this.props.course.END_DATE)
                    : Time.University(null, true), //End Date By
            [RecurringOptions.occurence]: 1, //End Date Occurence

            [RecurringOptions.repeatsEvery]: 1, //Recurrence

            weekdays: {}, //Weeklu Occurs on weekdays

            monthOccursOn: RecurringOptions.monthly.occursOnDay, //Monthly occurs on type
            monthlyDay: 1, //Month Occurs on Day
            monthlyWeek: 1, //Month Occurs on Week
            monthlyWeekDay: 0 //Month Occurs on: Weekday
        }
    };

    toggleIsLoading = (isLoading = !this.state.isLoading) => {
        this.setState({
            isLoading
        });
    };

    //Θ(1) returns the topic to be displayed in input
    getTopic = course => {
        let crns = course?.CRNS?.split(',');

        if (crns?.length) {
            let meetingTitle =
                course.SSBSECT_TERM_CODE +
                '_' +
                course.SUBJECT_CODE +
                '_' +
                course.COURSE_NUMBER +
                '_' +
                crns[0] +
                '_' +
                this.state.meetingTitle;
            return meetingTitle;
        } else return '';
    };

    //Θ(1) Sets meetingTitle, start and end time
    componentDidMount() {
        //If previous state was saved we load it
        if (Object.keys(this.props.adhocPanelDetails).length > 0) {
            this.setState({
                ...this.props.adhocPanelDetails
            });
            return;
        }

        let course = this.props.course;
        let crns = course.CRNS.split(',');
        let meetingTitle = course.SUBJECT_CODE + ' ' + course.COURSE_NUMBER;

        let currentTime = Time.University();
        let startTime = '';
        let endTime = '';
        if (currentTime.minute() >= 30) {
            startTime = currentTime
                .add(60 - (currentTime.minute() % 60), 'minutes')
                .format('YYYY-MM-DDTHH:mm');
            endTime = currentTime
                .add(60 - (currentTime.minute() % 60) + 60, 'minutes')
                .format('YYYY-MM-DDTHH:mm');
        } else {
            startTime = currentTime
                .subtract(currentTime.minute() % 60, 'minutes')
                .format('YYYY-MM-DDTHH:mm');
            endTime = currentTime
                .add((currentTime.minute() % 60) + 60, 'minutes')
                .format('YYYY-MM-DDTHH:mm');
        }

        this.setState({
            meetingTitle,
            [OptionsMapping.meetingStartTime]: dayjs(startTime),
            [OptionsMapping.meetingEndTime]: dayjs(endTime)
        });

        const term = Object.values(this.props.terms).find(
            term => term.code === course.SSBSECT_TERM_CODE
        );
        this.setState({
            termStartDate: term?.startDate,
            termEndDate: term?.endDate
        });
    }

    //Θ(1) Sets recurrence value
    onRecurrenceChange = event => {
        this.setState({recurrence: event.target.value});
    };

    //Θ(1) Sets respective checkbox value
    onCheckBoxUpdate = event => {
        this.setState({
            [event.target.name]: event.target.checked
        });
    };

    //Θ(1) Sets textfield value
    onMeetingTitleUpdate = value => {
        this.setState({
            meetingTitle: value
        });
    };

    //Θ(1) Sets date
    onStartDateChange = value => {
        let meetingDates = [];
        meetingDates.push(value);
        this.setState({
            meetingDates
        });
    };

    //Θ(1) Sets from and to date
    onDatesChange = values => {
        let meetingDates = values.map(value => {
            if (value)
                return Time.University(value['$d']).format(MeetingDateFormat);
        });

        this.setState({
            meetingDates
        });
    };

    //Θ(1) Sets time
    onTimeChange = (value, name) => {
        this.setState({
            [name]: value
        });
    };

    //Θ(N) where N is the length of other classes
    //Adds/Removed class from the share list
    onOtherClasses = event => {
        let otherClasses = this.state.otherClasses;
        let classDetails = otherClasses.find(
            otherClass => otherClass.CRNS === event.target.value.CRNS
        );
        if (classDetails) {
            otherClasses = otherClasses.filter(
                otherClass => otherClass.CRNS !== event.target.value.CRNS
            );
        } else {
            otherClasses.push(event.target.value);
        }
        this.setState({
            otherClasses,
            isShareError: otherClasses.length === 0
        });
    };

    //Θ(N) where N is length of other classes
    //Deletes class from the share list
    onDeleteOtherClass = classToDelete => {
        let otherClasses = this.state.otherClasses.filter(
            otherClass => otherClass.CRNS !== classToDelete.CRNS
        );

        let isShareError = false;

        if (otherClasses.length === 0) isShareError = true;

        this.setState({
            otherClasses,
            isShareError
        });
    };

    //Θ(N + M) where N is the length of days in week and M is the length of the classes to be shared with
    //Creates the body of post request and hits the api
    onSchedule = () => {
        this.toggleIsLoading(true);

        //if sharewith is checked and no courses added then we raise an error toast
        if (this.state.isShare && this.state.otherClasses.length === 0) {
            toast.error(
                'Share with other courses chosen, but no other courses picked. Choose courses to share this meeting with or unshare meeting.'
            );
            this.toggleIsLoading(false);
            return;
        }

        let course = this.props.course;

        //Basic details
        let email = getUserEmail(this.props.user, this.props.impersonation);
        let crns = course.CRNS;
        let termCode = course.SSBSECT_TERM_CODE;
        let topic = this.getTopic(course);

        let agenda = ''; //Empty in pprd

        //Using local time as we dont want the date selected in dropdown to change from Local to EST
        let date = Time.Local(this.state.meetingDates[0]['$d']).format(
            MeetingDateFormat
        );
        let beginTime = Time.Local(
            this.state[OptionsMapping.meetingStartTime]['$d']
        ).format(MeetingTimeFormat);

        //Duration
        let startMoment = Time.Local(
            this.state[OptionsMapping.meetingStartTime]['$d']
        );
        let endMoment = Time.Local(
            this.state[OptionsMapping.meetingEndTime]['$d']
        );
        let duration = endMoment.diff(startMoment, 'minutes').toString(); //Calculate total duration of meeting from start and end time

        //Basic details
        let record = this.state.isRecordMeeting;
        let waitingRoom = this.state[OptionsMapping.isEnableWaitingRoom];

        //Weekly - These elements are sent only if recurrence is weekly
        //Forms the weekly_days string from array [1,2,3] => "1,2,3"
        let weekly_days = Object.keys(this.state.recurring.weekdays).reduce(
            (weekly, weekDay, idx) => {
                return weekly + (idx !== 0 ? ',' : '') + weekDay;
            },
            ''
        );
        let isWeekly =
            RecurringOptions[this.state.recurrence].type ===
            RecurringOptions.weekly.type; //Checks if the recurrence is weekly

        //Sets date if end by date is selected under recurrence
        let isEndDateBy =
            this.state.recurring.endDate === RecurringOptions.endDateBy;
        //Sets after if end after is selected under recurrence
        let isEndDateAfter =
            this.state.recurring.endDate === RecurringOptions.endDateAfter;

        //Monthly - These elements are sent only if recurrence is monthly
        let monthly_day = this.state.recurring.monthlyDay;
        let monthly_week = this.state.recurring.monthlyWeek;
        let monthly_week_day = this.state.recurring.monthlyWeekDay;

        //Checks if is monthly recurrence and occurs on day
        let isMonthlyAndDay =
            RecurringOptions[this.state.recurrence].type ===
                RecurringOptions.monthly.type &&
            this.state.recurring.monthOccursOn ===
                RecurringOptions.monthly.occursOnDay;
        //Checks if is monthly recurrence and occurs on month setting
        let isMonthlyAndMonth =
            RecurringOptions[this.state.recurrence].type ===
                RecurringOptions.monthly.type &&
            this.state.recurring.monthOccursOn ===
                RecurringOptions.monthly.occursOnWeek;

        //Recurrence object
        let recurrence =
            this.state.recurrence !== RecurringOptions.noRepeat.value
                ? {
                      type: RecurringOptions[this.state.recurrence].type,
                      repeat_interval:
                          this.state.recurring[RecurringOptions.repeatsEvery],
                      ...(isEndDateBy && {
                          end_date_time: Time.Local(
                              this.state.recurring[RecurringOptions.byDate][
                                  '$d'
                              ]
                          ).format('YYYY-MM-DDTHH:MM:SS[Z]')
                      }),
                      ...(isEndDateAfter && {
                          end_times:
                              this.state.recurring[RecurringOptions.occurence]
                      }),

                      //Weekly
                      ...(isWeekly && {weekly_days}),

                      //Monthly
                      ...(isMonthlyAndDay && {monthly_day}),
                      ...(isMonthlyAndMonth && {monthly_week, monthly_week_day})
                  }
                : {};

        let shareWith = this.state.otherClasses.map(
            otherClass => otherClass.CRNS
        );

        //Returns of sharewith is checked and empty
        if (this.state[OptionsMapping.isShare] && !shareWith?.length) {
            toast.error('No other courses added');
            this.toggleIsLoading(false);
            return;
        }

        let meetingDetails = {
            email,
            crns,
            termCode,
            topic,
            agenda,
            date,
            beginTime,
            duration,
            record,
            waitingRoom,
            ...(this.state.recurrence !== RecurringOptions.noRepeat.value && {
                recurrence
            }),
            ...(this.state.isShare && {shareWith})
        };

        postData(adhoc, meetingDetails, true)
            .then(result => {
                if (!result) toast.error('Unable to create meeting');
                else {
                    toast.success('Meeting Created');
                    this.props.onTabChange('', ZoomTabMapping.currentMeeting);
                    this.props.toggleIsScheduleMeeting(false);
                }
                this.toggleIsLoading(false);
            })
            .catch(err => {
                this.toggleIsLoading(false);
                toast.error('Unable to create meeting');
                console.log(err);
            });
    };

    //Θ(1) Updates end date
    onEndDateChanged = event => {
        this.setState({
            recurring: {
                ...this.state.recurring,
                [event.target.name]: event.target.value
            }
        });
    };

    //Θ(1) Sets recurring object
    setRecurring = recurring => {
        this.setState({
            recurring
        });
    };

    //Θ(1) Checks if there is any error in form
    checkIsErrors = () => {
        return (
            !this.state?.meetingTitle?.trim().length ||
            this.state.recurring.byDate.diff(
                this.state.meetingDates[0],
                'day'
            ) < 0 ||
            this.state?.[OptionsMapping.meetingEndTime]?.diff(
                this.state?.[OptionsMapping.meetingStartTime],
                'minute'
            ) <= 0 ||
            this.state.isShareError
        );
    };

    //Θ(1) Cancel meeting creation
    onCancel = () => {
        this.props.saveAdhocPanelDetails(this.state);
        this.props.onToggle();
    };

    render() {
        const isError = this.checkIsErrors();

        const isEndTimeError =
            this.state?.[OptionsMapping.meetingEndTime]?.diff(
                this.state?.[OptionsMapping.meetingStartTime],
                'minute'
            ) <= 0;
        return (
            <React.Fragment>
                <Box sx={{marginTop: 2}}>
                    <Row>
                        <Col>
                            <Typography variant="h4" component="h4">
                                Schedule Adhoc Meetings
                            </Typography>
                        </Col>
                    </Row>
                    <Box sx={{paddingTop: '1rem'}}>
                        <Row style={{padding: 4}}>
                            <FormTextField
                                id="textfield-meeting-title"
                                label="Meeting Title"
                                onChange={this.onMeetingTitleUpdate}
                                value={this.state[OptionsMapping.meetingTitle]}
                                name={OptionsMapping.meetingTitle}
                                inputGridProps={{xs: 12, md: 10}}
                                labelGridProps={{xs: 12, md: 2}}
                                textAlign={{xs: 'start', md: 'end'}}
                                required
                                error={
                                    this.state.meetingTitle.trim().length === 0
                                }
                                errorText={'Title Required'}
                            />
                        </Row>
                        <Row style={{padding: 4}}>
                            <FormDropDown
                                label={'Repeats'}
                                options={[
                                    {
                                        label: 'Does Not Repeat',
                                        value: RecurringOptions.noRepeat.value
                                    },
                                    {
                                        label: 'Daily',
                                        value: RecurringOptions.daily.value
                                    },
                                    {
                                        label: 'Weekly',
                                        value: RecurringOptions.weekly.value
                                    },
                                    {
                                        label: 'Monthly',
                                        value: RecurringOptions.monthly.value
                                    }
                                ]}
                                labelGridProps={{xs: 12, md: 2}}
                                inputGridProps={{xs: 12, md: 4}}
                                onChange={this.onRecurrenceChange}
                                id="select-Recurrence"
                                value={this.state.recurrence}
                            />
                        </Row>
                        {this.state.recurrence !==
                            RecurringOptions.noRepeat.value && (
                            <RepeatMeetings
                                recurrence={
                                    RecurringOptions[this.state.recurrence]
                                }
                                meetingDates={this.state.meetingDates}
                                onEndDateChanged={this.onEndDateChanged}
                                recurring={this.state.recurring}
                                setRecurring={this.setRecurring}
                                onStartDateChange={this.onStartDateChange}
                                course={this.props.course}
                                termStartDate={this.state.termStartDate}
                                termEndDate={this.state.termEndDate}
                            />
                        )}
                        {!(
                            this.state.recurrence !==
                            RecurringOptions.noRepeat.value
                        ) && (
                            <Row style={{padding: 4}}>
                                <FormDatePicker
                                    value={this.state.meetingDates[0]}
                                    onChange={this.onStartDateChange}
                                    label={'Date'}
                                    inputGridProps={{xs: 12, md: 4}}
                                    labelGridProps={{xs: 12, md: 2}}
                                    minDate={dayjs(
                                        this.state.termStartDate ??
                                            this.props.course?.START_DATE
                                    )}
                                    maxDate={dayjs(
                                        this.state.termEndDate ??
                                            this.props.course?.END_DATE
                                    )}
                                />
                            </Row>
                        )}
                        <Row style={{padding: 4}}>
                            <Grid container spacing={{sx: 0, sm: 1}}>
                                <Grid
                                    item
                                    xs={12}
                                    md={2}
                                    textAlign={{xs: 'start', md: 'end'}}
                                    style={{
                                        paddingTop: this.props.isSmall ? 8 : 16
                                    }}
                                >
                                    <Typography className="myOdu__label">
                                        Time
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    md={10}
                                    wrap="nowrap"
                                    spacing={1}
                                >
                                    <Grid item xs={6} md={3}>
                                        <FormTimePicker
                                            name={'customStartTime'}
                                            value={
                                                this.state[
                                                    OptionsMapping
                                                        .meetingStartTime
                                                ]
                                            }
                                            onAccept={value => {
                                                this.onTimeChange(
                                                    value,
                                                    OptionsMapping.meetingStartTime
                                                );
                                            }}
                                            onChange={value => {
                                                this.onTimeChange(
                                                    value,
                                                    OptionsMapping.meetingStartTime
                                                );
                                            }}
                                            inputGridProps={{xs: 12}}
                                            labelGridProps={{xs: 0}}
                                            helperText={
                                                <PortalTooltip
                                                    title={
                                                        "Meetings are always scheduled in ODU's timezone which changes between Eastern Standard Time and Eastern Daylight Time"
                                                    }
                                                >
                                                    <Typography
                                                        style={{
                                                            cursor: 'pointer'
                                                        }}
                                                        variant="p"
                                                        className="myOdu__universityTime"
                                                    >
                                                        University Timezone
                                                    </Typography>
                                                </PortalTooltip>
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={'auto'}
                                        textAlign={'center'}
                                        style={{
                                            paddingTop: this.props.isSmall
                                                ? 8
                                                : 16
                                        }}
                                    >
                                        <Typography variant="p">To</Typography>
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <FormTimePicker
                                            name={'customEndTime'}
                                            value={
                                                this.state[
                                                    OptionsMapping
                                                        .meetingEndTime
                                                ]
                                            }
                                            onAccept={value =>
                                                this.onTimeChange(
                                                    value,
                                                    OptionsMapping.meetingEndTime
                                                )
                                            }
                                            onChange={value => {
                                                this.onTimeChange(
                                                    value,
                                                    OptionsMapping.meetingEndTime
                                                );
                                            }}
                                            inputGridProps={{xs: 12}}
                                            error={isEndTimeError}
                                            errorText={
                                                'End time must be after start time'
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Row>
                        <Row style={{padding: 4}}>
                            <Grid container spacing={{xs: 0, sm: 1}}>
                                <Grid
                                    item
                                    xs={12}
                                    md={2}
                                    textAlign={{xs: 'start', md: 'end'}}
                                >
                                    <Typography className="myOdu__label">
                                        Options
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={10} alignItems="center">
                                    <FormCheckbox
                                        label={
                                            'Share this meeting with my other courses'
                                        }
                                        onChange={this.onCheckBoxUpdate}
                                        name={OptionsMapping.isShare}
                                        labelGridProps={{
                                            xs: 0
                                        }}
                                        inputGridProps={{
                                            xs: 12,
                                            md: 9,
                                            lg: 8,
                                            xl: 9
                                        }}
                                        value={
                                            this.state[OptionsMapping.isShare]
                                        }
                                        sx={{marginRight: '0.25rem'}}
                                    />
                                    {this.state.isShare && (
                                        <FormDropDown
                                            options={
                                                this.props.courses.length
                                                    ? this.props?.courses
                                                          ?.filter(
                                                              course =>
                                                                  course.CRNS !==
                                                                      this.props
                                                                          .course
                                                                          .CRNS &&
                                                                  course.SSBSECT_TERM_CODE ===
                                                                      this.props
                                                                          .course
                                                                          .SSBSECT_TERM_CODE
                                                          )
                                                          .map(course => {
                                                              const isCourseSelected =
                                                                  this.state.otherClasses.find(
                                                                      otherClass =>
                                                                          otherClass.CRNS ===
                                                                          course.CRNS
                                                                  ) !==
                                                                  undefined;

                                                              return {
                                                                  label:
                                                                      course.COURSE_TITLE +
                                                                      ' (' +
                                                                      course.CRNS +
                                                                      ')',
                                                                  value: course,
                                                                  isSelected:
                                                                      isCourseSelected
                                                              };
                                                          })
                                                    : []
                                            }
                                            labelGridProps={{xs: 2}}
                                            inputGridProps={{xs: 12, md: 4}}
                                            onChange={this.onOtherClasses}
                                            id="select-shared-courses"
                                            // value={this.state.recurrence}
                                            value={this.state.otherClasses}
                                            showChecks
                                            error={this.state.isShareError}
                                            errorText={'No courses picked'}
                                            placeholder="Select other courses"
                                        />
                                    )}
                                    <FormGroup>
                                        <Row>
                                            {this.state.isShare && (
                                                <Row>
                                                    <Row>
                                                        <Stack
                                                            direction={'row'}
                                                            spacing={1}
                                                            sx={{
                                                                flexWrap: 'wrap'
                                                            }}
                                                        >
                                                            {this.state.otherClasses.map(
                                                                otherClass => {
                                                                    return (
                                                                        <Chip
                                                                            variant="outlined"
                                                                            label={
                                                                                otherClass.COURSE_TITLE +
                                                                                ' (' +
                                                                                otherClass.CRNS +
                                                                                ')'
                                                                            }
                                                                            onDelete={() => {
                                                                                this.onDeleteOtherClass(
                                                                                    otherClass
                                                                                );
                                                                            }}
                                                                            deleteIcon={
                                                                                <PortalTooltip
                                                                                    id={
                                                                                        otherClass.CRNS +
                                                                                        '_remove'
                                                                                    }
                                                                                    title="Remove from shared classes"
                                                                                >
                                                                                    <IconButton
                                                                                        onClick={() => {
                                                                                            this.onDeleteOtherClass(
                                                                                                otherClass
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        <FontAwesomeIcon
                                                                                            icon={
                                                                                                faCircleXmark
                                                                                            }
                                                                                            size="sm"
                                                                                            style={{
                                                                                                color: 'black'
                                                                                            }}
                                                                                        />
                                                                                    </IconButton>
                                                                                </PortalTooltip>
                                                                            }
                                                                            style={{
                                                                                margin: '0.25rem'
                                                                            }}
                                                                        />
                                                                    );
                                                                }
                                                            )}
                                                        </Stack>
                                                    </Row>
                                                </Row>
                                            )}
                                        </Row>
                                        <FormCheckbox
                                            label={'Record Meeting'}
                                            onChange={this.onCheckBoxUpdate}
                                            name={
                                                OptionsMapping.isRecordMeeting
                                            }
                                            labelGridProps={{
                                                xs: 0
                                            }}
                                            inputGridProps={{
                                                xs: 12,
                                                md: 9,
                                                lg: 8,
                                                xl: 9
                                            }}
                                            value={
                                                this.state[
                                                    OptionsMapping
                                                        .isRecordMeeting
                                                ]
                                            }
                                            sx={{marginRight: '0.25rem'}}
                                        />
                                        <FormCheckbox
                                            label={'Enable Waiting Room'}
                                            onChange={this.onCheckBoxUpdate}
                                            name={
                                                OptionsMapping.isEnableWaitingRoom
                                            }
                                            labelGridProps={{
                                                xs: 0
                                            }}
                                            inputGridProps={{
                                                xs: 12,
                                                md: 9,
                                                lg: 8,
                                                xl: 9
                                            }}
                                            value={
                                                this.state[
                                                    OptionsMapping
                                                        .isEnableWaitingRoom
                                                ]
                                            }
                                            sx={{marginRight: '0.25rem'}}
                                        />
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </Row>

                        <Stack
                            justifyContent={'flex-end'}
                            spacing={1}
                            direction={{xs: 'column', sm: 'row'}}
                            sx={{width: '100%', mt: 2}}
                        >
                            <Button
                                onClick={this.onSchedule}
                                disabled={this.state.isLoading || isError}
                                variant="outlined"
                                className="myOdu__button scheduleMeetings primary"
                            >
                                Schedule Adhoc Meetings
                            </Button>

                            <FormButton
                                label={'Cancel'}
                                id="backToScheduleMeeting"
                                onClick={() => this.onCancel()}
                                className="scheduleMeeting"
                            />
                        </Stack>
                    </Box>
                </Box>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        course: state.CCTReducer.course,
        courses: state.myCoursesReducer.coursesTeaching,
        user: state.AWSReducer.user,
        impersonation: state.impersonationReducer.impersonation,
        terms: state.termsReducer.terms
    };
};

export default connect(mapStateToProps)(WithSmallScreen(Adhoc));
