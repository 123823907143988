import PortalModal from '../common/PortalModal';
import {FormControlledDropdown} from '../common/Form/FormDropdown';
import {Grid} from '@mui/material';
import {getReasonOptions} from './options';
import {statusOptions} from './options';
import {gradeOptions} from './options';
import {FormControlledTextField} from '../common/Form/FormTextField';

export const BulkUpdateModal = ({
    methods,
    isModalOpen,
    setIsModalOpen,
    selectionModel,
    setSelectionModel
}) => {
    const applyBulkEdit = data => {
        selectionModel.forEach(id => {
            // Update fields for each selected row
            data.reason &&
                methods.setValue(`rows.${id}.reason`, data.reason, {
                    shouldDirty: true
                });
            data.status &&
                methods.setValue(`rows.${id}.status`, data.status, {
                    shouldDirty: true
                });
            data.grade &&
                methods.setValue(`rows.${id}.grade`, data.grade, {
                    shouldDirty: true
                });
            data.comment &&
                methods.setValue(`rows.${id}.comment`, data.comment, {
                    shouldDirty: true
                });
        });

        setSelectionModel([]);

        methods.resetField('bulkEdit');

        // Close the modal after applying
        setIsModalOpen(false);
    };

    const statusValue = methods.watch('bulkEdit.status');

    return (
        <PortalModal
            isOpen={isModalOpen}
            isCloseable
            title="Bulk Edit Selected Students"
            onClose={() => {
                setIsModalOpen(false);
                methods.resetField('bulkEdit');
            }}
            primaryButtonTitle="Update Students"
            maxWidth="md"
            onPrimaryClick={() => {
                const data = methods.getValues('bulkEdit');
                applyBulkEdit(data);
            }}
        >
            <Grid container rowGap={2}>
                <Grid item xs={12}>
                    <FormControlledDropdown
                        name="bulkEdit.status"
                        control={methods.control}
                        label="Status"
                        options={statusOptions}
                        textAlign={{xl: 'start'}}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlledDropdown
                        label="Explanation/Reason"
                        options={
                            getReasonOptions()[
                                statusValue?.toLowerCase() || '-'
                            ]
                        }
                        name="bulkEdit.reason"
                        control={methods.control}
                        textAlign={{xl: 'start'}}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlledDropdown
                        name="bulkEdit.grade"
                        control={methods.control}
                        label="Current Grade"
                        options={gradeOptions}
                        textAlign={{xl: 'start'}}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlledTextField
                        name="bulkEdit.comment"
                        control={methods.control}
                        label="Comment"
                        multiline
                        minRows={4}
                        maxRow={10}
                        textAlign={{xl: 'start'}}
                        isTextArea
                    />
                </Grid>
            </Grid>
        </PortalModal>
    );
};
