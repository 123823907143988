import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Stack, Box, Typography, TextField, Autocomplete} from '@mui/material';
import {saveImpersonation, toggleImpersonation} from './actions';
import {getData} from '../../DataAccessLayer';
import {impersonation, users} from '../../DataAccessLayer/services';
import PortalModal from '../common/PortalModal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUserSecret} from '@fortawesome/free-solid-svg-icons';
import {REDIRECT_TIMEOUT} from '../common/constants';

class Impersonation extends Component {
    state = {
        text: '',
        errorMessage: 'MIDAS not found',
        userOptions: [],
        isLoading: false
    };

    componentDidMount() {
        this.getUserOptions();
    }

    onChange = e => {
        this.setState({text: e.target.value});
    };

    impersonate = () => {
        const midas = this.state.text;
        // Set ignore impersonation here so impersonation header is not set
        // So we can use real user for BE authorization
        getData(impersonation + '/' + midas, true)
            .then(data => {
                data = {
                    ...data,
                    isImpersonating: true,
                    updatedAt: Date.now()
                };
                console.log(data);
                this.props.saveImpersonation(data);
                this.props.toggleImpersonation();
                this.setState({text: '', isError: false});
                // Hacky fix for impersionation failed to clear data for chrome.
                // We need to delay before reload
                setTimeout(() => (window.location = '/'), REDIRECT_TIMEOUT);
            })
            .catch(err => {
                this.setState({isError: true});
            });
    };

    getUserOptions = async () => {
        try {
            this.setState({isLoading: true});

            // Set ignore impersonation here so impersonation header is not set
            // So we can use real user for BE authorization
            const data = await getData(users(), true);

            this.setState({userOptions: data});
        } catch (err) {
            console.log(err);
        } finally {
            this.setState({isLoading: false});
        }
    };

    render() {
        const {isLoading, userOptions, text, isError, errorMessage} =
            this.state;

        return (
            <React.Fragment>
                <PortalModal
                    maxWidth="xs"
                    onPrimaryClick={this.impersonate}
                    onClose={this.props.toggleImpersonation}
                    isOpen={this.props.isImpersonation}
                    noHeader
                    id="impersonateModal"
                    primaryButtonTitle="Impersonate User"
                    isPrimaryButtonDisabled={!text}
                    isCloseable={true}
                >
                    <Stack direction="row" sx={{mb: 2}}>
                        <FontAwesomeIcon
                            icon={faUserSecret}
                            fixedWidth
                            size="lg"
                        />
                        <Typography id="transition-modal-title" component="h2">
                            Impersonate User
                        </Typography>
                    </Stack>

                    <Stack direction="row">
                        <Typography
                            id="transition-modal-title"
                            component="label"
                            className="myOdu__label"
                            sx={{mt: 1, mr: 1}}
                        >
                            User
                        </Typography>
                        <Box sx={{width: '100%'}}>
                            <Autocomplete
                                freeSolo
                                loading={isLoading}
                                options={userOptions}
                                getOptionLabel={option =>
                                    typeof option === 'string'
                                        ? option
                                        : `${option.name} (${option.midas})`
                                }
                                renderInput={params => (
                                    <TextField
                                        className="myOdu__hideFloatLabel"
                                        {...params}
                                        placeholder="MIDAS, Name or Email"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        error={isError}
                                        helperText={isError ? errorMessage : ''}
                                    />
                                )}
                                filterOptions={(options, {inputValue}) => {
                                    const filtered = options.filter(
                                        option =>
                                            option.midas
                                                .toLowerCase()
                                                .includes(
                                                    inputValue.toLowerCase()
                                                ) ||
                                            option.name
                                                .toLowerCase()
                                                .includes(
                                                    inputValue.toLowerCase()
                                                ) ||
                                            option.email
                                                .toLowerCase()
                                                .includes(
                                                    inputValue.toLowerCase()
                                                )
                                    );

                                    // Allow free text if no match is found
                                    if (inputValue !== '' && !filtered.length) {
                                        filtered.push(inputValue);
                                    }
                                    return filtered;
                                }}
                                onInputChange={(event, newValue) => {
                                    this.setState({text: newValue});
                                }}
                                onChange={(_, newValue) => {
                                    if (typeof newValue === 'string') {
                                        // FreeSolo input
                                        this.setState({text: newValue});
                                    } else if (newValue) {
                                        // Selected option
                                        this.setState({text: newValue.midas});
                                    } else {
                                        // Clear input
                                        this.setState({text: ''});
                                    }
                                }}
                            />
                        </Box>
                    </Stack>
                </PortalModal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        isImpersonation: state.impersonationReducer.isImpersonation
    };
};

const mapDispatchToProps = dispatch => ({
    toggleImpersonation: () => dispatch(toggleImpersonation()),
    saveImpersonation: impersonation =>
        dispatch(saveImpersonation(impersonation))
});

export default connect(mapStateToProps, mapDispatchToProps)(Impersonation);
