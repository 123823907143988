import React, {useEffect, useState} from 'react';
import {
    Typography,
    Breadcrumbs,
    Link,
    Stack,
    Box,
    Tabs,
    Tab,
    useMediaQuery
} from '@mui/material';

import TabPanel from '../../common/TabPanel';
import PortalModal from '../../common/PortalModal';

const MasterDetailsArea = ({table, className}) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('lg'));

    const [isModalOpen, setIsModalOpen] = useState(true);

    useEffect(() => {
        setIsModalOpen(table.detail.props.data);
    }, [table.detail.props.data]);

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    return isSmall ? (
        <div>
            {table.master}
            <PortalModal
                className={className}
                title={table.title}
                isOpen={isModalOpen}
                onClose={toggleModal}
                isCloseable
            >
                <Box className="detailsSection">{table.detail}</Box>
            </PortalModal>
        </div>
    ) : (
        <Stack direction="row" spacing={2} className="body">
            {table.master}
            <Box className="detailsSection">{table.detail}</Box>
        </Stack>
    );
};

export const MasterDetails = ({
    id,
    className,
    pageTitle,
    tables = null,
    children = null
}) => {
    const [tabSelected, setTabSelected] = useState(0);

    const handleChangeTab = (event, newValue) => {
        setTabSelected(newValue);
    };

    return (
        <div className={`myOdu__masterDetails ${className}`}>
            <Typography id={`${id}_masterDetails__pageTitle`} component="h2">
                {pageTitle}
            </Typography>

            <Breadcrumbs
                className="myOdu__breadcrumbs"
                id={`${id}_masterDetails__breadCrumbs_dashboard'`}
                aria-label="breadcrumb"
            >
                <Link
                    id={`${id}_masterDetails__breadCrumbs_linkDashboard`}
                    underline="hover"
                    href="/"
                >
                    Dashboard
                </Link>
                <Link
                    id={`${id}_masterDetails__breadCrumbs_linkAdminPanel`}
                    underline="hover"
                    href="/admin"
                >
                    Admin Panel
                </Link>
                <Typography id={`${id}_masterDetails__breadCrumbs_currentPage`}>
                    {pageTitle}
                </Typography>
            </Breadcrumbs>
            {tables &&
                (tables.length > 1 ? (
                    <div>
                        <Box
                            sx={{
                                borderBottom: 1,
                                borderColor: 'divider'
                            }}
                        >
                            <Tabs
                                className='"myOdu__tabs'
                                value={tabSelected}
                                onChange={handleChangeTab}
                                aria-label={'Choose Table'}
                            >
                                {tables.map((table, index) => (
                                    <Tab
                                        key={`${id}__tab_${index}`}
                                        label={table.title}
                                        id={`${id}__tab_${table.id}`}
                                        aria-controls={`${id}__tabPanel_${table.id}`}
                                    />
                                ))}
                            </Tabs>
                        </Box>
                        {tables.map((table, index) => (
                            <TabPanel
                                id={`${id}__tabPanel_${table.id}`}
                                key={`${id}__tabPanel_${index}`}
                                index={index}
                                className="myOdu__tabPanel"
                                value={tabSelected}
                                labelledBy={`${id}__tab_${table.id}`}
                            >
                                <MasterDetailsArea
                                    table={table}
                                    className={`myOdu__masterDetails ${className}`}
                                />
                            </TabPanel>
                        ))}
                    </div>
                ) : (
                    <MasterDetailsArea
                        table={tables[0]}
                        className={`myOdu__masterDetails ${className}`}
                    />
                ))}

            {children && <div className="body">{children}</div>}
        </div>
    );
};
