import React, {useState} from 'react';
import {Container} from 'reactstrap';
import {Box} from '@mui/material';

import {useNavigate, useParams} from 'react-router-dom';
import _ from 'lodash';
import {PageHeader} from './PageHeader';
import {Datatable} from './Datatable';
import Unauthorized from '../Layout/Router/Unauthorized';
import PortalPageLoader from '../common/PortalPageLoader';
import {AccessManagerModal} from './AccessManagerModal';
import {getPageDetails} from './config';
import {useCourseAccess} from './utils';

export const SSA = () => {
    const {termCode, crn} = useParams();
    const [isAccessManagerModalOpen, setIsAccessManagerModalOpen] =
        useState(false);
    const navigate = useNavigate();

    const {courseAccess, isLoading, isTestingAllowed} = useCourseAccess(
        termCode,
        crn
    );

    const minCrn = courseAccess?.course?.CRNS?.length
        ? Math.min(...courseAccess.course.CRNS.map(Number)).toString()
        : crn;

    const {page: reportPage, title: reportTitle} = getPageDetails({
        termCode,
        crn
    })['report'];

    const pageActions = [
        {
            title: reportTitle,
            handler: () => navigate(reportPage)
        }
    ];

    if (courseAccess.isSSASuperUser) {
        pageActions.push({
            title: 'Manage Access',
            handler: () => setIsAccessManagerModalOpen(true)
        });
    }

    return isLoading ? (
        <PortalPageLoader />
    ) : !courseAccess.isSSAUser && !courseAccess.isSSASuperUser ? (
        <Unauthorized />
    ) : (
        <div className="myOdu__ssa">
            <AccessManagerModal
                termCode={termCode}
                crn={minCrn}
                isOpen={isAccessManagerModalOpen}
                onClose={() => setIsAccessManagerModalOpen(false)}
            />
            <Container className="myOdu__container secondaryPage layout">
                <Box id="ssa__box_wrapper" className="wrapper">
                    <PageHeader
                        course={courseAccess.course}
                        actions={pageActions}
                        isTestingAllowed={isTestingAllowed}
                    />
                    <Datatable
                        crn={minCrn}
                        termCode={termCode}
                        isTestingAllowed={isTestingAllowed}
                    />
                </Box>
            </Container>
        </div>
    );
};
