import React, {useEffect, useState} from 'react';
import {Typography} from '@mui/material';
import PortalModal from '../common/PortalModal';
import {useBlocker} from 'react-router-dom';
import {useInterceptWindowOpen} from '../common/utils';

export const UnsavedChangesModal = ({methods}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [pendingExternalUrl, setPendingExternalUrl] = useState(null);
    const {isDirty} = methods.formState;

    useInterceptWindowOpen({
        isDirty,
        setOpenUnsavedChangesModal: setIsModalOpen,
        setPendingExternalUrl,
        pendingExternalUrl
    });

    useEffect(() => {
        const handleBeforeUnload = event => {
            if (isDirty) {
                event.preventDefault();
                event.returnValue = '';
            }
        };

        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden' && isDirty) {
                setIsModalOpen(true);
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            document.removeEventListener(
                'visibilitychange',
                handleVisibilityChange
            );
        };
    }, [isDirty]);

    const blocker = useBlocker(tx => {
        if (isDirty) {
            setIsModalOpen(true);
            return true; // Block the navigation
        }
        return false; // Allow navigation
    });

    const handleStay = () => {
        setPendingExternalUrl(null);
        setIsModalOpen(false);

        if (blocker.location) {
            blocker.reset();
        }
    };

    const handleLeave = () => {
        setIsModalOpen(false);
        methods.reset();

        if (blocker.location) {
            blocker.proceed();
        }
    };

    return (
        <PortalModal
            isOpen={isModalOpen}
            isCloseable
            title="You have unsaved changes"
            onClose={() => {
                setIsModalOpen(false);
            }}
            primaryButtonTitle="Stay"
            onPrimaryClick={handleStay}
            maxWidth="md"
            onSecondaryClick={handleLeave}
            secondaryButtonTitle="Leave"
        >
            <Typography>
                You have unsaved changes. Are you sure you want to leave?
            </Typography>
        </PortalModal>
    );
};
