import React, {useState} from 'react';
import {IconButton} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClock, faMemo} from '@fortawesome/pro-regular-svg-icons';
import {ChangesHistoryModal} from './ChangesHistoryModal';

export const ChangesHistory = ({row}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <ChangesHistoryModal
                row={row}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
            />

            <IconButton
                className="myOdu__iconButton small cell fa-layers fa-fw"
                onClick={() => setIsModalOpen(true)}
            >
                <FontAwesomeIcon
                    icon={faMemo}
                    style={{fontSize: '1rem', zIndex: 1, color: 'black'}}
                />
                <FontAwesomeIcon
                    icon={faClock}
                    style={{
                        zIndex: 2,
                        fontSize: '0.8rem',
                        position: 'absolute',
                        transform: 'translate(50%, 50%)',
                        backgroundColor: 'white',
                        color: 'black'
                    }}
                />
            </IconButton>
        </>
    );
};
