import React from 'react';
import {
    Box,
    Paper,
    IconButton,
    useMediaQuery,
    useTheme,
    Typography,
    Stack,
    Button
} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/pro-regular-svg-icons';

export const FooterActionBar = ({
    selectionModel = [],
    setSelectionModel,
    actionComponents = []
}) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    if (selectionModel.length === 0) return null;

    return (
        <Box display="flex" justifyContent="center" alignItems="center">
            <Paper
                sx={{
                    position: 'fixed',
                    bottom: 5,
                    zIndex: 1000,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    p: 2,
                    borderRadius: 2,
                    border: '1px solid #ddd',
                    width: {xs: 375, md: 600}
                }}
            >
                <Stack
                    direction={{sm: 'column', md: 'row'}}
                    gap={1}
                    alignItems="center"
                    width="100%"
                >
                    <Box
                        sx={{
                            backgroundColor: '#1F8098',
                            color: '#fff',
                            fontWeight: 'bold',
                            fontSize: '1.5rem',
                            borderRadius: '8px',
                            minWidth: '50px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '50px'
                        }}
                    >
                        {selectionModel.length}
                    </Box>
                    <Typography>
                        Item{selectionModel.length > 1 && 's'} selected
                    </Typography>

                    <Stack
                        direction={{sm: 'column', md: 'row'}}
                        gap={1}
                        width={{xs: '100%', md: 'auto'}}
                    >
                        {actionComponents}
                    </Stack>

                    {isSmallScreen ? (
                        <Button
                            variant="outlined"
                            className="myOdu__button primary"
                            onClick={() => setSelectionModel([])}
                            fullWidth
                        >
                            Close
                        </Button>
                    ) : (
                        <IconButton
                            aria-label="close"
                            onClick={() => setSelectionModel([])}
                            className="myOdu__iconButton closeIcon"
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </IconButton>
                    )}
                </Stack>
            </Paper>
        </Box>
    );
};
