import {Time} from '../../common/MomentTime';

const getColumns = (tableType, actionFunction) => {
    return tableType === 'aggregated'
        ? [
              {
                  field: 'lastSeen',
                  headerName: 'Date/Time',
                  width: 180,
                  valueGetter: (params, row) =>
                      Time.University(row.lastSeen).format('MM/DD/YYYY h:mm A')
              },
              {
                  field: 'error',
                  headerName: 'Error',
                  width: 200,
                  valueGetter: (params, row) =>
                      typeof row.error === 'object'
                          ? JSON.stringify(row.error)
                          : row.error
              },
              {
                  field: 'count',
                  headerName: 'Count',
                  width: 120
              }
          ]
        : [
              {
                  field: 'MIDAS',
                  headerName: 'MIDAS ID',

                  width: 140
              },
              {
                  field: 'CREATED_AT',
                  headerName: 'Date/Time',
                  valueGetter: (params, row) =>
                      Time.University(row.CREATED_AT).format(
                          'MM/DD/YYYY h:mm A'
                      ),
                  width: 160
              },
              {
                  field: 'ERROR',
                  headerName: 'Error',
                  valueGetter: (params, row) =>
                      typeof row.ERROR === 'object'
                          ? JSON.stringify(row.ERROR)
                          : row.ERROR,
                  width: 200
              }
          ];
};

export default getColumns;
