import React, {useState, useRef, useEffect} from 'react';
import {ClickAwayListener, Typography} from '@mui/material';

export const HoverEditableCell = ({
    rowId,
    value,
    field,
    editor,
    hoveredCells,
    onMouseEnterCell,
    onMouseLeaveCell,
    onClickedCell,
    rules,
    isEditable
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const inputRef = useRef(null);

    const isHovered = hoveredCells.some(
        cell => cell.id === rowId && cell.field === field
    );

    const showEditor = (isHovered || isEditing) && isEditable;

    // Focus the input and set cursor to end whenever the editor is shown
    useEffect(() => {
        if (showEditor && inputRef.current) {
            const inputElement = inputRef.current;
            if (inputElement) {
                inputElement.focus();
                const length = inputElement.value.length;
                inputElement.setSelectionRange(length, length);
            }
        }
    }, [showEditor]);

    const handleOnCellClick = (rowId, field) => {
        handleStartEditing();
        onClickedCell(rowId, field);
    };
    const handleStartEditing = () => setIsEditing(true);
    const handleStopEditing = () => setIsEditing(false);

    const clonedEditor = editor
        ? React.cloneElement(editor, {
              ...(editor.type.name === 'FormTextField' && {inputRef})
          })
        : null;

    return (
        <ClickAwayListener
            onClickAway={() => {
                // If the user clicks anywhere outside this <div> *and* outside the dropdown,
                // we stop editing:
                if (isEditing) {
                    handleStopEditing();
                }
            }}
        >
            <div
                style={{width: '100%', height: '100%', cursor: 'pointer'}}
                onMouseEnter={() => onMouseEnterCell(rowId, field)}
                onMouseLeave={() => {
                    if (isEditing) {
                        onMouseLeaveCell(rowId, field);
                    }
                }}
                onClick={() => handleOnCellClick(rowId, field)}
                onKeyDown={e => e.stopPropagation()}
            >
                {showEditor ? (
                    clonedEditor
                ) : (
                    <span
                        style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '100%',
                            display: 'block'
                        }}
                    >
                        {rules?.required ? clonedEditor : value || '-'}
                    </span>
                )}
            </div>
        </ClickAwayListener>
    );
};
