import React from 'react';
import {Box, Container} from '@mui/material';
import {useParams} from 'react-router-dom';
import {Report} from './Report';
import {PageHeader} from './PageHeader';
import {useCourseAccess} from './utils';
import PortalPageLoader from '../common/PortalPageLoader';
import Unauthorized from '../Layout/Router/Unauthorized';

export const ReportPage = () => {
    const {termCode, crn} = useParams();

    const {courseAccess, isLoading, isTestingAllowed} = useCourseAccess(
        termCode,
        crn
    );

    const minCrn = courseAccess?.course?.CRNS?.length
        ? Math.min(...courseAccess.course.CRNS.map(Number)).toString()
        : crn;

    return isLoading ? (
        <PortalPageLoader />
    ) : !courseAccess.isSSAUser && !courseAccess.isSSASuperUser ? (
        <Unauthorized />
    ) : (
        <div className="myOdu__ssa">
            <Container className="myOdu__container secondaryPage layout">
                <Box id={`ssa__box_wrapper`} className={'wrapper'}>
                    <PageHeader
                        course={courseAccess.course}
                        isTestingAllowed={isTestingAllowed}
                    />
                    <Report
                        termCode={termCode}
                        crn={minCrn}
                        isTestingAllowed={isTestingAllowed}
                    />
                </Box>
            </Container>
        </div>
    );
};
