import {Box, Button, Divider, Grid} from '@mui/material';
import React, {useState} from 'react';
import {ReportData} from './ReportData';
import {Time} from '../../common/MomentTime';
import {WithSmallScreen} from '../../common/WithSmallScreen';
import {postData} from '../../../DataAccessLayer';
import {ssa} from '../../../DataAccessLayer/services';
import {toast} from 'react-toastify';

export const ReportSummary = WithSmallScreen(
    ({record, id, isSM, refresh, isRetriable}) => {
        const smallScreenDateFormat = 'MMM D, YYYY h:mm A';
        const dateFormat = 'LLL';

        const handleRetry = async () => {
            const [termCode, crn] = record.TERM_CODE_CRN?.split('_') ?? [];

            const jobId = record?.CREATED_AT;
            const jobIdShort = jobId?.toString().slice(-5);

            try {
                await postData(
                    ssa.retry(),
                    {
                        crn,
                        termCode,
                        jobCreatedAt: jobId
                    },
                    true
                );

                toast.success(`Job ${jobIdShort} reran successfully!`);
            } catch (err) {
                toast.error(`Job ${jobIdShort} reran failed!`);
            } finally {
                await refresh({jobId});
            }
        };

        return (
            <Box id={`${id}__box`}>
                <Grid
                    container
                    spacing={1}
                    direction={{
                        xs: 'column',
                        sm: 'row',
                        md: 'column',
                        lg: 'row'
                    }}
                >
                    {/* Left Section */}
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={5}
                        maxHeight={{md: '60px !important', lg: 'auto'}}
                    >
                        <ReportData
                            id={id}
                            label="Update Started"
                            data={Time.University(record?.CREATED_AT).format(
                                isSM ? smallScreenDateFormat : dateFormat
                            )}
                            direction={{
                                xs: 'column',
                                sm: 'column',
                                md: 'row',
                                lg: 'row',
                                xl: 'row'
                            }}
                            spacing={{xs: 0, sm: 0, md: 1, lg: 0, xl: 1}}
                            className="myOdu__smaller"
                            dataClassName="pb-sm-3 pb-md-0"
                        />
                        <ReportData
                            id={id}
                            label="Update Completed"
                            data={Time.University(record?.IS_COMPLETED).format(
                                isSM ? smallScreenDateFormat : dateFormat
                            )}
                            direction={{
                                xs: 'column',
                                sm: 'column',
                                md: 'row',
                                lg: 'row',
                                xl: 'row'
                            }}
                            spacing={{xs: 0, sm: 0, md: 1, lg: 0, xl: 1}}
                            className="myOdu__smaller"
                        />

                        <ReportData
                            id={id}
                            label="Performed By"
                            data={record?.ACTION_PERFORMED_BY || 'N/A'}
                            direction={{
                                xs: 'column',
                                sm: 'column',
                                md: 'row',
                                lg: 'row',
                                xl: 'row'
                            }}
                            spacing={{xs: 0, sm: 0, md: 1, lg: 0, xl: 1}}
                            className="myOdu__smaller"
                        />
                    </Grid>

                    {/* Vertical Divider */}
                    <Grid item>
                        <Divider
                            orientation="vertical"
                            sx={{borderColor: 'black'}}
                        />
                    </Grid>

                    {/* Right Section */}
                    <Grid item xs={12} sm={12} md={6}>
                        {[
                            {
                                label: 'Total Processed',
                                data: record?.PROCESSED_COUNT
                            },
                            {
                                label: 'Successful Updated',
                                data: record?.SUCCESS_COUNT
                            },
                            {
                                label: 'Could Not Update',
                                data: record?.ERROR_COUNT,
                                className: 'myOdu__error',
                                action: {
                                    title: 'Retry Update',
                                    handler: handleRetry,
                                    disabled:
                                        !isRetriable ||
                                        !record?.IS_RETRY_ALLOWED
                                }
                            }
                        ].map(({label, data, className = '', action}) => (
                            <ReportData
                                key={label}
                                id={id}
                                label={label}
                                data={data}
                                direction={{
                                    xs: 'column',
                                    sm: 'column',
                                    md: 'row',
                                    lg: 'row',
                                    xl: 'row'
                                }}
                                spacing={{xs: 0, sm: 0, md: 1, lg: 1, xl: 1}}
                                className={`myOdu__smaller ${className}`}
                                action={action}
                            />
                        ))}
                    </Grid>
                </Grid>
            </Box>
        );
    }
);
