import React, {useEffect} from 'react';
import {HoverEditableCell} from './HoverEditableCell';
import {FormDropDown} from '../Form/FormDropdown';
import _ from 'lodash';

export const CellControlledDropDown = ({
    field,
    params,
    methods,
    options,
    selectionModel,
    hoveredCells,
    onMouseEnterCell,
    onMouseLeaveCell,
    onClickedCell,
    rules,
    isEditable,
    isDisabled
}) => {
    const rowId = params.row.id;
    const currentValue = params.value;

    const option = options.find(o => o.value === currentValue);
    const value = option?.label || '';
    const name = `rows.${rowId}.${field}`;

    useEffect(() => {
        if (rules.required) {
            methods.setError(name, {message: rules.required});
        } else {
            methods.clearErrors(name);
        }
    }, [rules.required, name, methods]);

    return (
        <HoverEditableCell
            selectionModel={selectionModel}
            value={value}
            editor={
                <FormDropDown
                    name={name}
                    defaultValue={methods.getValues(name)}
                    options={options}
                    inputGridProps={{xs: 12, mt: 1, mb: 1}}
                    autoFocus={
                        hoveredCells.length === 1 &&
                        hoveredCells[0].id === rowId &&
                        hoveredCells[0].field === field
                    }
                    onChange={event => {
                        const {value} = event.target;
                        // If rowId is selected, update all in selectionModel
                        if (
                            selectionModel.includes(rowId) &&
                            selectionModel.length > 1
                        ) {
                            selectionModel.forEach(selectedId => {
                                methods.setValue(
                                    `rows.${selectedId}.${field}`,
                                    value,
                                    {shouldDirty: true}
                                );
                            });
                        } else {
                            // Otherwise, just update this one row
                            methods.setValue(name, value, {shouldDirty: true});
                        }
                    }}
                    sx={{
                        height: '30px !important',

                        '& .MuiSelect-select': {
                            fontSize: '0.875rem' // Adjust font size of selected value
                        },

                        '& .MuiOutlinedInput-notchedOutline': {
                            borderWidth: '1px !important' // Force a 1px border width
                        }
                    }}
                    error={Boolean(rules.required)}
                    errorText={rules.required}
                    errorTextShortened={rules.errorTextShortened}
                    disabled={isDisabled}
                />
            }
            field={field}
            rowId={rowId}
            hoveredCells={hoveredCells}
            onMouseEnterCell={onMouseEnterCell}
            onMouseLeaveCell={onMouseLeaveCell}
            onClickedCell={onClickedCell}
            rules={rules}
            isEditable={isEditable}
        />
    );
};
