import React, {useEffect} from 'react';
import {debounce} from 'lodash';
import {FormTextField} from '../Form/FormTextField';
import {HoverEditableCell} from './HoverEditableCell';

export const CellControlledTextArea = ({
    field,
    params,
    methods,
    selectionModel,
    hoveredCells,
    onMouseEnterCell,
    onMouseLeaveCell,
    onClickedCell,
    rules,
    isEditable
}) => {
    const rowId = params.row.id;
    const value = params.value;
    const name = `rows.${rowId}.${field}`;

    useEffect(() => {
        if (rules.required) {
            methods.setError(name, {message: rules.required});
        } else {
            methods.clearErrors(name);
        }
    }, [rules.required, name, methods]);

    return (
        <HoverEditableCell
            value={value}
            editor={
                <FormTextField
                    name={name}
                    defaultValue={methods.getValues(name)}
                    multiline
                    minRows={1}
                    maxRows={3}
                    inputGridProps={{xs: 12, mt: 1, mb: 1}}
                    autoFocus={
                        hoveredCells.length === 1 &&
                        hoveredCells[0].id === rowId &&
                        hoveredCells[0].field === field
                    }
                    onChange={value => {
                        // If rowId is selected, update all in selectionModel
                        const updateValue = debounce(value => {
                            if (
                                selectionModel.includes(rowId) &&
                                selectionModel.length > 1
                            ) {
                                selectionModel.forEach(selectedId => {
                                    methods.setValue(
                                        `rows.${selectedId}.${field}`,
                                        value,
                                        {
                                            shouldDirty: true
                                        }
                                    );
                                });
                            } else {
                                methods.setValue(name, value, {
                                    shouldDirty: true
                                });
                            }
                        }, 300);

                        updateValue(value);
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            fontSize: '0.875rem',
                            padding: '2px 14px',
                            minHeight: '30px'
                        },

                        '& .MuiOutlinedInput-notchedOutline': {
                            borderWidth: '1px !important'
                        }
                    }}
                    charCountEnabled
                    maxChars={150}
                    error={Boolean(rules.required)}
                    errorText={rules.required}
                />
            }
            field={field}
            rowId={rowId}
            hoveredCells={hoveredCells}
            onMouseEnterCell={onMouseEnterCell}
            onMouseLeaveCell={onMouseLeaveCell}
            onClickedCell={onClickedCell}
            rules={rules}
            isEditable={isEditable}
        />
    );
};
