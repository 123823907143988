import {faFaceRaisedEyebrow} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Typography, Button, Stack, Link} from '@mui/material';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {errors} from '../../../DataAccessLayer/services';
import {postDataPublic} from '../../../DataAccessLayer';

class Unauthorized extends Component {
    state = {};

    componentDidMount() {
        this.logError();
    }

    logError = () => {
        const user = this.props.isImpersonating
            ? this.props.impersonation
            : this.props.user;
        let error = new Error();
        error.code = 401;
        error.message = 'Unauthorized access';
        error.location = window.location.pathname;
        postDataPublic(errors.create, {error, midas: user.midas});
    };

    render() {
        return (
            <React.Fragment>
                <Stack alignItems={'center'}>
                    <FontAwesomeIcon
                        icon={faFaceRaisedEyebrow}
                        fixedWidth
                        size="9x"
                    />
                    <Typography component="h2" className="myOdu__errorCode">
                        401
                    </Typography>
                    <Typography component="h3" className="myOdu__errorCode">
                        Unauthorized Access
                    </Typography>
                    <Typography component="p" className="myOdu__errorCode">
                        You need permissions to view this page.
                    </Typography>
                    <Button
                        className="myOdu__errorCodeButton"
                        href="/"
                        sx={{mt: 3}}
                        variant="outlined"
                    >
                        Go to the home page
                    </Button>
                </Stack>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        isImpersonating:
            state.impersonationReducer.impersonation?.isImpersonating,
        user: state.AWSReducer.user,
        impersonation: state.impersonationReducer.impersonation
    };
};

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Unauthorized);
