import React from 'react';
import PortalModal from '../common/PortalModal';
import {PortalTable} from '../common/DataTable/PortalTable';
import {getSubRowColumns} from './columns';

export const ChangesHistoryModal = ({row, isModalOpen, setIsModalOpen}) => {
    return (
        <PortalModal
            isOpen={isModalOpen}
            isCloseable
            title="Changes History"
            onClose={() => {
                setIsModalOpen(false);
            }}
            maxWidth="md"
        >
            <PortalTable
                row={{subRows: row.updates}}
                subRowColumns={getSubRowColumns()}
            />
        </PortalModal>
    );
};
