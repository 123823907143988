import React, {useEffect, useState} from 'react';
import PortalModal from '../common/PortalModal';
import {FormButton} from '../common/Form/FormButton';
import {Typography, Box, Stack, LinearProgress} from '@mui/material';
import _, {set} from 'lodash';
import {toast} from 'react-toastify';

import {FormAutoComplete} from '../common/Form/FormAutoComplete';
import {FormCheckbox} from '../common/Form/FormCheckbox';
import {deleteData, getData, patchData, postData} from '../../DataAccessLayer';
import {roleBasedUsers, ssa, users} from '../../DataAccessLayer/services';
import PortalDataGridPro from '../common/PortalDataGridPro';
import {GRID_CHECKBOX_SELECTION_FIELD} from '@mui/x-data-grid-pro';
import {WithSmallScreen} from '../common/WithSmallScreen';
import ConfirmationDialog from './ConfirmationDialog';

export const AccessManagerModal = WithSmallScreen(
    ({isOpen, onClose, isSmall, crn, termCode, ...props}) => {
        const MAX_SELECTION = 100;

        const [usersData, setUsersData] = useState([]);
        const [isStudentsSelector, setisStudentsSelector] = useState(false);

        const [isGrantConfirmation, setIsGrantConfirmation] = useState(false);
        const [isRevokeConfirmation, setIsRevokeConfirmation] = useState(false);
        const [
            isStudentsSelectorConfirmation,
            setisStudentsSelectorConfirmation
        ] = useState(false);

        const [isFacultyLoading, setIsFacultyLoading] = useState(true);
        const [isStudentLoading, setIsStudentLoading] = useState(true);
        const [isLoading, setIsLoading] = useState(false);

        const [facultyOptions, setFacultyOptions] = useState({});
        const [studentOptions, setStudentOptions] = useState({});
        const [pinned, setPinned] = useState({
            left: [GRID_CHECKBOX_SELECTION_FIELD, 'lastName'],
            right: []
        });

        const [selectedUsers, setSelectedUsers] = useState([]);
        const [selectedFaculty, setSelectedFaculty] = useState([]);
        const [selectedStudents, setSelectedStudents] = useState([]);

        //θ(1) Loads Faculty Data
        const loadFacultyOptions = async () => {
            try {
                const data = await getData(roleBasedUsers('FACULTY'));
                setFacultyOptions(data);
                setIsFacultyLoading(false);
            } catch (err) {}
        };

        //θ(1) Loads Student Data
        const loadStudentOptions = async () => {
            try {
                const data = await getData(roleBasedUsers('STUDENT'));
                setStudentOptions(data);
                setIsStudentLoading(false);
            } catch (err) {}
        };

        //θ(n) Where n is the number of users
        //Loads Users Data
        const loadUsersData = async () => {
            setIsLoading(true);
            try {
                const data = await getData(ssa.users(termCode, crn));
                const users = data.reduce((acc, midas) => {
                    const user =
                        facultyOptions?.[midas] || studentOptions?.[midas];
                    if (user) acc[midas] = user;
                    return acc;
                }, {});
                setUsersData(users);
            } catch (err) {
                console.log(err);
            }
            setIsLoading(false);
        };

        //θ(1) Loads Data
        const loadData = async () => {
            loadFacultyOptions();
            loadStudentOptions();
        };

        //θ(1) useEffect to load data
        useEffect(() => {
            loadData();
        }, []);

        //θ(1) useEffect to load users data
        useEffect(() => {
            if (!isFacultyLoading && !isStudentLoading) {
                loadUsersData();
            }
        }, [isFacultyLoading, isStudentLoading]);

        //θ(1) Handles Student Selector Change
        const handleisStudentsSelector = (selected = !isStudentsSelector) => {
            //Showcase confirmation box if students are selected and the user tries to hide the student selector
            if (
                isStudentsSelector &&
                !isStudentsSelectorConfirmation &&
                selectedStudents.length
            ) {
                setisStudentsSelectorConfirmation(true);
                return;
            }
            setisStudentsSelectorConfirmation(false);

            setisStudentsSelector(selected);
            if (!isStudentsSelector) {
                setSelectedStudents([]);
            }
        };

        //θ(1) Handles Change for autocomplete
        const handleChange = (event, newValue, setSelectedValues) => {
            if (newValue.length <= MAX_SELECTION) {
                setSelectedValues(newValue);
            }
        };

        //θ(1) Handles Grant Access
        const handleGrantAccess = async () => {
            //Showcase confirmation box if students are selected and the user tries to grant access
            if (
                isStudentsSelector &&
                selectedStudents.length &&
                !isGrantConfirmation
            ) {
                setIsGrantConfirmation(true);
                return;
            }
            setIsGrantConfirmation(false);
            setIsLoading(true);
            const users = [
                ...selectedStudents.map(faculty => faculty.midas),
                ...selectedFaculty.map(faculty => faculty.midas)
            ];
            const body = {
                users,
                crn,
                termCode
            };
            try {
                await postData(ssa.grant(), body, true);
                toast.success('Users Added!');
                loadUsersData();
                setSelectedFaculty([]);
                setSelectedStudents([]);
            } catch (err) {
                toast.success('Adding Users Failed!');
                console.log(err);
                setIsLoading(false);
            }
        };

        //θ(1) Handles Row Check for datagrid
        const handleRowCheck = newRowSelectionModel => {
            setSelectedUsers(newRowSelectionModel);
        };

        //θ(1) Handles Revoke Access
        const handleRevokeAccess = async () => {
            setIsLoading(true);
            //Showcase confirmation box if users are selected and the user tries to revoke access
            setIsRevokeConfirmation(false);
            const users = selectedUsers;
            const body = {
                users,
                crn,
                termCode
            };
            try {
                await postData(ssa.revoke(), body, true);

                toast.success('Users Removed!');
                loadUsersData();
            } catch (err) {
                toast.error('Removing Users Failed!');
                console.log(err);
                setIsLoading(false);
            }
        };

        return (
            <PortalModal
                maxWidth="lg"
                isOpen={isOpen}
                onClose={onClose}
                title="Manage Access"
                isCloseable
                isFullScreen={isSmall}
            >
                <ConfirmationDialog
                    isOpen={isGrantConfirmation}
                    onClose={() => {
                        setIsGrantConfirmation(false);
                    }}
                    title="Grant Access"
                    message={
                        'Are you sure you want to grant access to selected students?'
                    }
                    onConfirm={handleGrantAccess}
                    primaryButtonTitle={'Yes, Grant Access'}
                    secondaryButtonTitle={'No, Cancel'}
                />
                <ConfirmationDialog
                    isOpen={isRevokeConfirmation}
                    onClose={() => {
                        setIsRevokeConfirmation(false);
                    }}
                    title="Revoke Access"
                    message={
                        'Are you sure you want to revoke access from selected users?'
                    }
                    onConfirm={handleRevokeAccess}
                    primaryButtonTitle={'Yes, Revoke Access'}
                    secondaryButtonTitle={'No, Cancel'}
                />
                <ConfirmationDialog
                    isOpen={isStudentsSelectorConfirmation}
                    title="Hide Student Selector"
                    onClose={() => {
                        setisStudentsSelectorConfirmation(false);
                    }}
                    message={
                        'Are you sure you want to hide the student selector? \n Hiding the student selector without granting access will remove the selected students.'
                    }
                    onConfirm={() => {
                        handleisStudentsSelector(false);
                    }}
                    primaryButtonTitle={'Yes, Hide List'}
                    secondaryButtonTitle={'No, Do Not Hide List'}
                />
                <Box sx={{padding: 1}}>
                    <Typography variant="h2">Grant Access</Typography>
                    <Stack gap={0} mt={2}>
                        <Stack gap={1}>
                            {/* Faculty Selection */}
                            <FormAutoComplete
                                value={selectedFaculty}
                                label="Faculty"
                                inputGridProps={{xs: 12}}
                                labelGridProps={{xs: 0}}
                                options={Object.values(facultyOptions)}
                                getOptionLabel={option =>
                                    typeof option === 'string'
                                        ? option
                                        : `${option.name} (${option.midas})`
                                }
                                getOptionDisabled={option => {
                                    return Boolean(
                                        Object.values(usersData).find(
                                            user => user.midas === option.midas
                                        )
                                    );
                                }}
                                placeholder="Search by name or MIDAS ID"
                                onChange={(e, value) =>
                                    handleChange(e, value, setSelectedFaculty)
                                }
                                autocompleteProps={{multiple: true}}
                                ariaLabel="Faculty Selection"
                            />

                            <Box
                                width="100%"
                                display="flex"
                                flexDirection={{xs: 'column', sm: 'row'}}
                                alignItems="center"
                                justifyContent={{xs: 'center', sm: 'flex-end'}}
                                gap={1}
                            >
                                {selectedFaculty.length >= MAX_SELECTION && (
                                    <Typography
                                        variant="subtitle2"
                                        className="myOdu__error"
                                    >
                                        Maximum of 100 faculty allowed to be
                                        added at one time.
                                    </Typography>
                                )}
                            </Box>

                            {/* Student Selection */}
                            {isStudentsSelector && (
                                <FormAutoComplete
                                    value={selectedStudents}
                                    label="Student"
                                    inputGridProps={{xs: 12}}
                                    labelGridProps={{xs: 0}}
                                    options={Object.values(studentOptions)}
                                    getOptionLabel={option =>
                                        typeof option === 'string'
                                            ? option
                                            : `${option.name} (${option.midas})`
                                    }
                                    getOptionDisabled={option => {
                                        return Boolean(
                                            Object.values(usersData).find(
                                                user =>
                                                    user.midas === option.midas
                                            )
                                        );
                                    }}
                                    placeholder="Search by name or MIDAS ID"
                                    onChange={(e, value) =>
                                        handleChange(
                                            e,
                                            value,
                                            setSelectedStudents
                                        )
                                    }
                                    autocompleteProps={{multiple: true}}
                                    ariaLabel="Student Selection"
                                />
                            )}
                        </Stack>

                        <FormCheckbox
                            value={isStudentsSelector}
                            label={
                                'Display student selector to grant access to selected students.'
                            }
                            onChange={e =>
                                handleisStudentsSelector(e.target.checked)
                            }
                            labelGridProps={{xs: 0}}
                            id="student__checkbox_grantAccess"
                        />

                        <Stack gap={2}>
                            <Box
                                width="100%"
                                display="flex"
                                flexDirection={{xs: 'column', sm: 'row'}}
                                alignItems="center"
                                justifyContent={{xs: 'center', sm: 'flex-end'}}
                                gap={1}
                            >
                                {selectedStudents.length >= MAX_SELECTION && (
                                    <Typography
                                        variant="subtitle2"
                                        className="myOdu__error"
                                    >
                                        Maximum of 100 students allowed to be
                                        added at one time.
                                    </Typography>
                                )}

                                <FormButton
                                    label="Grant Access"
                                    className="primary"
                                    sx={{width: {xs: '100%', sm: 'auto'}}}
                                    onClick={handleGrantAccess}
                                    disabled={
                                        (!selectedStudents.length &&
                                            !selectedFaculty.length) ||
                                        isLoading ||
                                        isFacultyLoading ||
                                        isStudentLoading
                                    }
                                />
                            </Box>

                            <Box
                                display={'flex'}
                                justifyContent={{
                                    xs: 'flex-start',
                                    sm: 'space-between'
                                }}
                                flexDirection={{xs: 'column', sm: 'row'}}
                                gap={1}
                            >
                                {/* Users Table */}
                                <Typography variant="h2">Users</Typography>
                                {/* Revoke Access Button */}
                                <Box
                                    width="100%"
                                    display="flex"
                                    justifyContent={{
                                        xs: 'center',
                                        sm: 'flex-end'
                                    }}
                                >
                                    <FormButton
                                        label="Revoke Access"
                                        className="primary"
                                        sx={{width: {xs: '100%', sm: 'auto'}}}
                                        onClick={() =>
                                            setIsRevokeConfirmation(true)
                                        }
                                        disabled={!selectedUsers.length}
                                    />
                                </Box>
                            </Box>

                            <PortalDataGridPro
                                className="myOdu__resultTable"
                                columns={[
                                    {
                                        field: 'lastName',
                                        headerName: 'Last Name',
                                        width: !isSmall ? 430 : 100,
                                        renderCell: params =>
                                            _.capitalize(params.row.lastName)
                                    },
                                    {
                                        field: 'firstName',
                                        headerName: 'First Name',
                                        width: 150,
                                        renderCell: params =>
                                            _.capitalize(params.row.firstName)
                                    },
                                    {
                                        field: 'midas',
                                        headerName: 'MIDAS ID',
                                        width: 150
                                    },
                                    {
                                        field: 'role',
                                        headerName: 'Role',
                                        width: 150,
                                        valueGetter: (value, row) => {
                                            if (
                                                facultyOptions[row.midas] &&
                                                studentOptions[row.midas]
                                            ) {
                                                return 'Faculty, Student';
                                            } else if (
                                                facultyOptions[row.midas]
                                            ) {
                                                return 'Faculty';
                                            } else if (
                                                studentOptions[row.midas]
                                            ) {
                                                return 'Student';
                                            }
                                        }
                                    }
                                ]}
                                checkboxSelection
                                onRowSelectionModelChange={handleRowCheck}
                                getRowId={row => row.midas}
                                rows={Object.values(usersData)}
                                disableRowSelectionOnClick
                                autoHeight
                                pagination
                                initialState={{
                                    sorting: {
                                        sortModel: [
                                            {field: 'lastName', sort: 'asc'}
                                        ]
                                    },
                                    pagination: {
                                        paginationModel: {pageSize: 20}
                                    }
                                }}
                                pinnedColumns={
                                    props.isSmall || props.isMD || props.isLG
                                        ? {}
                                        : pinned
                                }
                                onPinnedColumnsChange={newPinned =>
                                    setPinned(newPinned)
                                }
                                isLoading={
                                    isLoading ||
                                    isFacultyLoading ||
                                    isStudentLoading
                                }
                                slots={{
                                    loadingOverlay: LinearProgress,
                                    noRowsOverlay: () => (
                                        <Box
                                            sx={{
                                                margin: 1
                                            }}
                                        >
                                            <Typography
                                                variant="p"
                                                component="p"
                                            >
                                                No Data Available
                                            </Typography>
                                        </Box>
                                    )
                                }}
                                getRowHeight={params => 'auto'}
                                sx={{
                                    '& .MuiDataGrid-row.Mui-selected': {
                                        backgroundColor:
                                            'rgba(0, 0, 0, 0.08) !important'
                                    },
                                    '& .MuiDataGrid-row:hover': {
                                        backgroundColor:
                                            'rgba(0, 0, 0, 0.04) !important'
                                    }
                                }}
                            />
                        </Stack>
                    </Stack>
                </Box>
            </PortalModal>
        );
    }
);
