//Θ(N) where N is the length of email
//Checks if the email is a valid email
export const isValidEmail = (email) => {
    if(email.length === 0)
        return true;
    let isValidEmail = email.includes('@odu.edu') || email.includes('@online-pprd.odu.edu')
    return isValidEmail;
}

//Θ(N) where N is the length of email
//Returns the object containing email with the number of occurences.
export const emailOccurences = (emails = []) => {
    const emailCounts = {}
    
    emails.forEach(email => {
        if(emailCounts[email]) {
            emailCounts[email] = emailCounts[email] + 1;
        } else {
            emailCounts[email] = 1;
        }
    });

    return emailCounts;
}

//Θ(N) where N is the length of email
//Checks if there is any duplicate email in the list
export const hasDuplicate = (emails) => {
    const emailSet = new Set();

    const isDuplicateError = emails.some(email => {
        if(emailSet.has(email)) {
            return true;
        } else {
            emailSet.add(email);
        }

        return false;
    });

    return isDuplicateError;
}

//Θ(N) where N is the number of emails
//Checks if the emails are valid
export const isValidEmails = (emails) => {
    let isValid = true

    emails.forEach(email => {
        if(!isValidEmail(email)) {
            isValid = false;
        }
    });

    return isValid;
}