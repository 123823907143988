import {
    Typography,
    Button,
    Grid,
    FormControl,
    RadioGroup,
    FormControlLabel,
    FormLabel,
    Radio
} from '@mui/material';
import React, {Component} from 'react';
import PortalModal from '../../common/PortalModal';
import {AccessManagerModal} from '../../SSA/AccessManagerModal';

class PortalModalTests extends Component {
    state = {
        isModalOpen: false,
        severity: '',
        maxWidth: 'sm',
        isImperative: false,
        isNoHeader: false,
        isCloseable: true,
        isFullScreen: false,
        isFeedbackOpen: false,
        isImpersonation: false,
        impersonationText: '',
        isBookmarksOpen: false,
        primaryButtonTitle: 'Add',
        secondaryButtonTitle: 'Add',
        title: 'Test Modal Title',
        subTitle: '',
        isEAMModalOpen: false
    };

    toggleIsModalOpen = () => {
        console.log('toggleIsModalOpen');
        this.setState({isModalOpen: !this.state.isModalOpen});
    };

    onClickPrimary = () => {
        console.log('primary button clicked');
    };

    onClickSecondary = () => {
        console.log('secondary button clicked');
    };

    setSeverity = severity => {
        console.log('severity');
        this.setState({severity: severity});
    };

    setMaxWidth = maxWidth => {
        console.log('maxWidth');
        this.setState({maxWidth: maxWidth});
    };

    setIsImperative = isImperative => {
        console.log('isImperative');
        this.setState({isImperative: isImperative});
    };

    setIsNoHeader = isNoHeader => {
        console.log('setIsNoHeader');
        this.setState({isNoHeader: isNoHeader});
    };

    setIsFullScreen = isFullScreen => {
        console.log('setIsFullScreen' + isFullScreen);
        this.setState({isFullScreen: isFullScreen});
    };

    setPrimaryButtonTitle = primaryButtonTitle => {
        console.log('primaryButtonTitle' + primaryButtonTitle);
        this.setState({primaryButtonTitle: primaryButtonTitle});
    };

    setSecondaryButtonTitle = secondaryButtonTitle => {
        console.log('secondaryButtonTitle' + secondaryButtonTitle);
        this.setState({secondaryButtonTitle: secondaryButtonTitle});
    };

    setTitle = title => {
        console.log('title' + title);
        this.setState({title: title});
    };

    setSubTitle = subTitle => {
        console.log('subTitle' + subTitle);
        this.setState({subTitle: subTitle});
    };

    setIsClosable = isCloseable => {
        console.log('isCloseable' + isCloseable);
        this.setState({isCloseable: isCloseable});
    };

    render() {
        const testModalProps = {
            severity:
                this.state.severity +
                (this.state.isImperative ? ' imperative' : ''),
            noHeader: this.state.isNoHeader,
            maxWidth: this.state.maxWidth,
            onClose: this.toggleIsModalOpen,
            onPrimaryClick: this.onClickPrimary,
            isOpen: this.state.isModalOpen,
            title: this.state.title,
            subTitle: this.state.subTitle,
            id: 'testModalButton',
            primaryButtonTitle: this.state.primaryButtonTitle,
            onSecondaryClick: this.onClickSecondary,
            secondaryButtonTitle: this.state.secondaryButtonTitle,
            isCloseable: this.state.isCloseable,
            isFullScreen: this.state.isFullScreen
        };
        return (
            <React.Fragment>
                <Grid container direction="column" gap={2}>
                    <Grid item>
                        <FormControl>
                            <FormLabel id="closeable-radio-group-label">
                                Closeable?
                            </FormLabel>
                            <RadioGroup
                                aria-labelledby="closeable-radio-group-label"
                                defaultValue="true"
                                name="closeable-options"
                                onChange={e =>
                                    this.setIsClosable(
                                        e.target.value === 'true'
                                    )
                                }
                            >
                                {[
                                    {label: 'Yes', value: 'true'},
                                    {
                                        label: 'No (Will require page refresh)',
                                        value: 'false'
                                    }
                                ].map(item => (
                                    <FormControlLabel
                                        value={item.value}
                                        control={<Radio />}
                                        label={item.label}
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl>
                            <FormLabel id="header-radio-group-label">
                                Header?
                            </FormLabel>
                            <RadioGroup
                                aria-labelledby="header-radio-group-label"
                                defaultValue="false"
                                name="header-options"
                                onChange={e =>
                                    this.setIsNoHeader(
                                        e.target.value === 'true'
                                    )
                                }
                            >
                                {[
                                    {label: 'Has Header', value: 'false'},
                                    {label: 'No Header', value: 'true'}
                                ].map(item => (
                                    <FormControlLabel
                                        value={item.value}
                                        control={<Radio />}
                                        label={item.label}
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl>
                            <FormLabel id="imperative-radio-group-label">
                                Imperative?
                            </FormLabel>
                            <RadioGroup
                                aria-labelledby="imperative-radio-group-label"
                                defaultValue="false"
                                name="imperative-options"
                                onChange={e =>
                                    this.setIsImperative(
                                        e.target.value === 'true'
                                    )
                                }
                            >
                                {[
                                    {label: 'Yes', value: 'true'},
                                    {label: 'No', value: 'false'}
                                ].map(item => (
                                    <FormControlLabel
                                        value={item.value}
                                        control={<Radio />}
                                        label={item.label}
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl>
                            <FormLabel id="screen-size-radio-group-label">
                                Screen Size?
                            </FormLabel>
                            <RadioGroup
                                aria-labelledby="screen-size-radio-group-label"
                                defaultValue="false"
                                name="screen-size-options"
                                onChange={e =>
                                    this.setIsFullScreen(
                                        e.target.value === 'true'
                                    )
                                }
                            >
                                {[
                                    {label: 'Regular', value: 'false'},
                                    {label: 'Full Screen', value: 'true'}
                                ].map(item => (
                                    <FormControlLabel
                                        value={item.value}
                                        control={<Radio />}
                                        label={item.label}
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl>
                            <FormLabel id="maxWidth-radio-group-label">
                                Max Width?
                            </FormLabel>
                            <RadioGroup
                                aria-labelledby="maxWidth-radio-group-label"
                                defaultValue="sm"
                                name="maxWidth-options"
                                onChange={e => this.setMaxWidth(e.target.value)}
                            >
                                {['xs', 'sm', 'md', 'lg', 'xl'].map(item => (
                                    <FormControlLabel
                                        value={item}
                                        control={<Radio />}
                                        label={item}
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl>
                            <FormLabel id="severity-radio-group-label">
                                Severity?
                            </FormLabel>
                            <RadioGroup
                                aria-labelledby="severity-radio-group-label"
                                defaultValue=""
                                name="severity-options"
                                onChange={e => this.setSeverity(e.target.value)}
                            >
                                {[
                                    {label: 'Normal', value: ''},
                                    {label: 'Success', value: 'success'},
                                    {label: 'Error', value: 'error'}
                                ].map(item => (
                                    <FormControlLabel
                                        value={item.value}
                                        control={<Radio />}
                                        label={item.label}
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl>
                            <FormLabel id="title-button-radio-group-label">
                                Title?
                            </FormLabel>
                            <RadioGroup
                                aria-labelledby="title-button-radio-group-label"
                                defaultValue="Test Modal Title"
                                name="title-button-options"
                                onChange={e => this.setTitle(e.target.value)}
                            >
                                {[
                                    {
                                        label: 'Yes',
                                        value: 'Test Modal Title'
                                    },
                                    {label: 'No', value: ''}
                                ].map(item => (
                                    <FormControlLabel
                                        value={item.value}
                                        control={<Radio />}
                                        label={item.label}
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl>
                            <FormLabel id="subTitle-button-radio-group-label">
                                Subtitle?
                            </FormLabel>
                            <RadioGroup
                                aria-labelledby="subTitle-button-radio-group-label"
                                defaultValue=""
                                name="subTitle-button-options"
                                onChange={e => this.setSubTitle(e.target.value)}
                            >
                                {[
                                    {
                                        label: 'Yes',
                                        value: 'Test Modal Subtitle'
                                    },
                                    {label: 'No', value: ''}
                                ].map(item => (
                                    <FormControlLabel
                                        value={item.value}
                                        control={<Radio />}
                                        label={item.label}
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl>
                            <FormLabel id="primary-button-radio-group-label">
                                Primary Button?
                            </FormLabel>
                            <RadioGroup
                                aria-labelledby="primary-button-radio-group-label"
                                defaultValue="Add"
                                name="primary-button-options"
                                onChange={e =>
                                    this.setPrimaryButtonTitle(e.target.value)
                                }
                            >
                                {[
                                    {label: 'None', value: ''},
                                    {label: 'Regular', value: 'Add'},
                                    {
                                        label: 'Long',
                                        value: 'Button with very long title to test wrapping and layout design'
                                    }
                                ].map(item => (
                                    <FormControlLabel
                                        value={item.value}
                                        control={<Radio />}
                                        label={item.label}
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl>
                            <FormLabel id="secondary-button-radio-group-label">
                                Secondary Button?
                            </FormLabel>
                            <RadioGroup
                                aria-labelledby="secondary-button-button-radio-group-label"
                                defaultValue="Add"
                                name="secondary-button-button-options"
                                onChange={e =>
                                    this.setSecondaryButtonTitle(e.target.value)
                                }
                            >
                                {[
                                    {label: 'None', value: ''},
                                    {label: 'Regular', value: 'Add'},
                                    {
                                        label: 'Long',
                                        value: 'Button with very long title to test wrapping and layout design'
                                    }
                                ].map(item => (
                                    <FormControlLabel
                                        value={item.value}
                                        control={<Radio />}
                                        label={item.label}
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>

                <Button
                    className="myOdu__button primary me-1"
                    onClick={() => this.toggleIsModalOpen()}
                >
                    Open Modal With Selected Props
                </Button>

                <Button
                    className="myOdu__button primary me-1"
                    onClick={() => this.setState({isEAMModalOpen: true})}
                >
                    Open Modal SSA EAM
                </Button>

                {/* <Button className="myOdu__button primary me-1" onClick={this.onClickRegular}>Severity="success"</Button>
                <Button className="myOdu__button primary me-1"  onClick={this.onClickRegular}>Severity="success imperative"</Button>
                <Button className="myOdu__button primary me-1"  onClick={this.onClickRegular}>Severity="error"</Button>
                <Button className="myOdu__button primary me-1" onClick={this.onClickRegular}>Severity="error imperative"</Button> */}
                <PortalModal {...testModalProps}>
                    <Typography variant="h5" component="h3">
                        H3 Nothing within a modal should be bigger than an H3.
                    </Typography>
                    Exercitation amet amet esse ut elit. Sint occaecat pariatur
                    proident exercitation veniam cillum ea.
                </PortalModal>

                <AccessManagerModal
                    isOpen={this.state.isEAMModalOpen}
                    onClose={() =>
                        this.setState({
                            isEAMModalOpen: false
                        })
                    }
                />
            </React.Fragment>
        );
    }
}

export default PortalModalTests;
