import React, {useState, useEffect} from 'react';
import {Button, Grid, Skeleton, Stack, Typography} from '@mui/material';
import {FormDatePicker} from '../../common/Form/FormDatePicker';
import {ReportCard} from './ReportCard';
import {getData} from '../../../DataAccessLayer';
import {Time} from '../../common/MomentTime';
import PortalAlert from '../../common/PortalAlert';
import ApiErrorMessage from '../../common/ApiErrorMessage';
import {ssa} from '../../../DataAccessLayer/services';
import {SSA_STATUS_POLLING_INTERVAL} from '../../common/constants';
import {pollingStatus} from '../utils';
import {useSelector} from 'react-redux';
import {isUserAdmin} from '../../Layout/utils';

export const Report = ({
    id = 'ssaReport',
    widgetName = 'Processing Report',
    termCode,
    crn,
    isTestingAllowed = false
}) => {
    const [studentUpdateRecords, setStudentUpdateRecords] = useState([]);
    const [startDate, setStartDate] = useState(
        Time.University(null, true).subtract(1, 'day')
    );

    const [endDate, setEndDate] = useState(Time.University(null, true));

    const maxDate = Time.University(null, true);

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [cachedData, setCachedData] = useState({});
    const [isRetriable, setIsRetriable] = useState(false);
    const termCodes = useSelector(state => state.termsReducer?.terms ?? {});

    const trueUser = useSelector(state => state.AWSReducer.user);

    const isCourseActive =
        isTestingAllowed ||
        isUserAdmin(trueUser) ||
        Boolean(termCodes.CURRENT_TERM?.code === termCode);

    const isEndDateValid = endDate
        .startOf('day')
        .isAfter(startDate.startOf('day'));

    useEffect(() => {
        loadPageData();
    }, []);

    const getStatus = async () => {
        try {
            setIsRetriable(false);
            const data = await getData(ssa.status({termCode, crn}, true));

            setIsRetriable(!data.isProcessing);
            return data.isProcessing;
        } catch (err) {}
    };

    const loadPageData = async () => {
        await pollingStatus(
            getStatus,
            data => data === false,
            SSA_STATUS_POLLING_INTERVAL
        );

        await getJobs();
    };

    const getJobs = async () => {
        setIsError(false);
        setIsLoading(true);

        try {
            const data = await getData(
                ssa.getJobs({
                    termCode,
                    crn,
                    startDate: startDate.format('YYYY-MM-DD'),
                    endDate: endDate.format('YYYY-MM-DD')
                }),
                true
            );

            setStudentUpdateRecords(data);
        } catch (err) {
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const addCachedData = ({jobId, data}) => {
        const newCachedData = cachedData;
        newCachedData[jobId] = data;

        setCachedData(newCachedData);
    };

    const refersh = async ({jobId}) => {
        await loadPageData();

        // Reset cached for that report
        const newCachedData = cachedData;
        newCachedData[jobId] = null;

        setCachedData(newCachedData);
    };

    return (
        <div className="myOdu__report">
            {(isError && (
                <PortalAlert
                    id={`${id}__portalAlert`}
                    severity="error"
                    type="statusAlert"
                >
                    <ApiErrorMessage
                        id={`${id}__apiErrorMessage`}
                        reload={loadPageData}
                        widgetName={widgetName}
                        isPage={true}
                    />
                </PortalAlert>
            )) || (
                <Stack direction={'column'} spacing={2}>
                    <Grid
                        container
                        spacing={1}
                        alignItems={{
                            xs: 'flex-start',
                            md: 'center',
                            lg: 'center'
                        }}
                        direction={{xs: 'column', md: 'row', lg: 'row'}}
                    >
                        <Grid
                            item
                            xs={12}
                            md={'auto'}
                            lg={'auto'}
                            paddingLeft={{xs: '0 !important'}}
                        >
                            <Typography className="myOdu__label ssaReport">
                                From
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={4}
                            lg={3}
                            paddingLeft={{
                                xs: '0 !important',
                                md: '8px !important'
                            }}
                        >
                            <FormDatePicker
                                inputGridProps={{xs: 12}}
                                value={startDate}
                                onChange={value => setStartDate(value)}
                                maxDate={maxDate}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={'auto'}
                            textAlign={'center'}
                            paddingLeft={{
                                xs: '0 !important',
                                md: '8px !important'
                            }}
                        >
                            <Typography className="myOdu__label ssaReport">
                                To
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={4}
                            lg={3}
                            paddingLeft={{
                                xs: '0 !important',
                                md: '8px !important'
                            }}
                        >
                            <FormDatePicker
                                inputGridProps={{xs: 12}}
                                value={endDate}
                                onChange={value => setEndDate(value)}
                                minDate={startDate}
                                maxDate={maxDate}
                                error={!isEndDateValid}
                                errorText={'End date must be after start date.'}
                            />
                        </Grid>
                        <Grid
                            item
                            paddingLeft={{
                                xs: '0 !important',
                                lg: '8px !important'
                            }}
                        >
                            <Button
                                variant="outlined"
                                onClick={() => getJobs()}
                                size="medium"
                                disabled={!isEndDateValid}
                            >
                                Get Reports
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction={'column'}
                        spacing={1}
                        sx={{width: '100%'}}
                    >
                        {studentUpdateRecords.length > 0 &&
                            studentUpdateRecords.map((record, index) => {
                                return (
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            width: '100%',
                                            paddingLeft: '0px !important'
                                        }}
                                    >
                                        <ReportCard
                                            isRetriable={
                                                isRetriable && isCourseActive
                                            }
                                            record={record}
                                            id={record.CREATED_AT}
                                            isExpanded={index === 0}
                                            cachedData={cachedData}
                                            addCachedData={addCachedData}
                                            refresh={refersh}
                                        />
                                    </Grid>
                                );
                            })}
                        {studentUpdateRecords.length === 0 && !isLoading && (
                            <Grid item xs={12}>
                                <Typography component={'p'} variant={'p'}>
                                    No updates found for the given date range.
                                </Typography>
                            </Grid>
                        )}
                        {isLoading &&
                            new Array(4).fill(0).map((_, index) => {
                                return (
                                    <Grid item>
                                        <Skeleton
                                            variant="rectangular"
                                            height={index === 0 ? 100 : 50}
                                            sx={{borderRadius: 2}}
                                            animation={'wave'}
                                        />
                                    </Grid>
                                );
                            })}
                    </Grid>
                </Stack>
            )}
        </div>
    );
};
