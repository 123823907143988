import {Grid, Typography, Button} from '@mui/material';
import React from 'react';

export const ReportData = ({
    label,
    data,
    spacing,
    direction,
    id,
    className,
    dataClassName,
    action
}) => {
    return (
        <Grid
            container
            spacing={spacing}
            direction={direction}
            alignItems={{xs: 'start', md: 'center'}}
        >
            <Grid item xs={4} sm={7} md={6} lg={6} id={`${id}_label`}>
                <Typography
                    id={`${id}_label_text`}
                    className={`myOdu__label ssaReport ${className}`}
                    whiteSpace="nowrap"
                >
                    {label}
                </Typography>
            </Grid>
            <Grid item id={`${id}_data`} xs="auto">
                <Typography
                    className={`data ${className} ${dataClassName}`}
                    id={`${id}_data_text`}
                    whiteSpace="nowrap"
                >
                    {data}
                </Typography>
            </Grid>
            {action && (
                <Grid item xs="auto" ml={{xs: 0, md: 2}}>
                    <Button
                        className="myOdu__button"
                        size="small"
                        variant="outlined"
                        onClick={() => action.handler()}
                        disabled={action.disabled}
                    >
                        {action.title}
                    </Button>
                </Grid>
            )}
        </Grid>
    );
};
