import React, {Component} from 'react';
import {Box, Typography, Paper, Stack, Breadcrumbs, Link} from '@mui/material';
import FeedbackTable from './FeedbackTable';
import getColumns from './columns';
import {feedback as feedbackService} from '../../../DataAccessLayer/services';
import {getData, patchData} from '../../../DataAccessLayer';
import FeedbackDetails from './FeedbackDetails';
import {GridToolbar} from '@mui/x-data-grid-pro';
import {BreakpointBoxes} from '../../common/BreakpointBoxes';
import {WithSmallScreen} from '../../common/WithSmallScreen';
import {toast} from 'react-toastify';
import {MasterDetails} from '../MasterDetails';

class Feedback extends Component {
    constructor(props) {
        super(props);
        this.state = {
            feedbackData: [],
            feedbackMap: {},
            isLoading: false,
            selectedFeedback: null
        };
    }

    componentDidMount() {
        this.loadAllFeedback();
    }

    loadAllFeedback = async () => {
        this.setState({isLoading: true});
        try {
            const data = await getData(feedbackService.feedback);

            // Create a mapping of feedback data for quick lookup
            const feedbackMap = data.reduce((acc, item) => {
                acc[`${item.MIDAS}-${item.TIMESTAMP}`] = item;
                return acc;
            }, {});

            this.setState({feedbackData: data, feedbackMap});
        } catch (err) {
            console.error('Failed to load feedbacks:', err);
        } finally {
            this.setState({isLoading: false});
        }
    };

    getSpecificFeedBack = async ({row}) => {
        const {MIDAS: midas, TIMESTAMP: timestamp} = row;

        const key = `${midas}-${timestamp}`;
        const data = this.state.feedbackMap[key];

        if (data) {
            this.setState({
                selectedFeedback: {
                    midas: data.MIDAS,
                    timestamp: data.TIMESTAMP,
                    bugDetail: data.BUG_DETAIL,
                    changeDetail: data.CHANGE_DETAIL,
                    ideaDetail: data.IDEA_DETAIL,
                    metadata: data.METADATA,
                    otherDetail: data.OTHER_DETAIL,
                    pageUrl: data.PAGE_URL,
                    rating: data.RATING,
                    note: data.NOTE || '',
                    isReviewed: !!data.IS_REVIEWED
                }
            });
        }
    };

    markAsReviewed = async row => {
        const {
            MIDAS: midas,
            TIMESTAMP: timestamp,
            IS_REVIEWED: isReviewed
        } = row;
        try {
            const updatedIsReview = !!!isReviewed;
            await patchData(
                feedbackService.update({midas, timestamp}),
                {
                    isReviewed: updatedIsReview
                },
                true
            );

            //TODO: uncomment when close all toasters implemented
            // also, change message to checked or unchecked instead what's below
            // toast.success(
            //     `Feedback ${updatedIsReview ? 'Checked' : 'Unchecked'}`
            // );
        } catch (err) {
            toast.error('Update Feedback Reviewed Failed');
        } finally {
            this.loadAllFeedback();
        }
    };

    onSaveNote = async ({midas, timestamp, note}) => {
        try {
            await patchData(
                feedbackService.update({midas, timestamp}),
                {
                    note
                },
                true
            );
            toast.success('Feedback Note Saved!');
        } catch (err) {
            toast.error('Update Feedback Note Failed');
        } finally {
            this.loadAllFeedback();
        }
    };

    render() {
        const {feedbackData, isLoading, selectedFeedback} = this.state;

        return (
            <MasterDetails
                id="adminFeedback"
                pageTitle="Feedback"
                className="adminFeedback"
                tables={[
                    {
                        id: 'feedback_table',
                        title: 'Feedback',
                        master: (
                            <FeedbackTable
                                className="myOdu__table tableSection"
                                rows={feedbackData}
                                columns={getColumns(this.markAsReviewed)}
                                isLoading={isLoading}
                                onClick={this.getSpecificFeedBack}
                                slots={{toolbar: GridToolbar}}
                                slotProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        csvOptions: {
                                            disableToolbarButton: true
                                        },
                                        printOptions: {
                                            disableToolbarButton: true
                                        },
                                        quickFilterProps: {
                                            sx: {m: 1, width: '100%'},
                                            variant: 'outlined',
                                            size: 'small',
                                            placeholder: 'Search Feedback'
                                        }
                                    }
                                }}
                                columnVisibilityModel={{
                                    PAGE_URL: false,
                                    BUG_DETAIL: false,
                                    IDEA_DETAIL: false,
                                    CHANGE_DETAIL: false,
                                    OTHER_DETAIL: false,
                                    METADATA: false
                                }}
                                disableColumnFilter
                                disableColumnSelector
                                disableDensitySelector
                            />
                        ),
                        detail: (
                            <FeedbackDetails
                                data={selectedFeedback}
                                onSaveNote={this.onSaveNote}
                            />
                        )
                    }
                ]}
            />
        );
    }
}

export default WithSmallScreen(Feedback);
