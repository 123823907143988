import React, {useEffect, useState} from 'react';
import {Button, Box, Typography} from '@mui/material';
import PortalDataGridPro from '../common/PortalDataGridPro';
import {useGridApiRef} from '@mui/x-data-grid-pro';

import {getColumns} from './columns';
import {useForm, FormProvider, useWatch} from 'react-hook-form';

import _ from 'lodash';

import {FooterActionBar} from '../common/DataTable/FooterActionBar';
import {BulkUpdateModal} from './BulkUpdateModal';
import {toast} from 'react-toastify';
import {UnsavedChangesModal} from './UnsavedChangesModal';
import {WithSmallScreen} from '../common/WithSmallScreen';
import {getData, postData} from '../../DataAccessLayer';
import {ssa} from '../../DataAccessLayer/services';
import dayjs from 'dayjs';
import {pollingStatus} from './utils';
import {SSA_STATUS_POLLING_INTERVAL} from '../common/constants';
import {useSelector} from 'react-redux';
import {isUserAdmin} from '../Layout/utils';

export const Datatable = WithSmallScreen(
    ({crn, termCode, isTestingAllowed = false, ...props}) => {
        const apiRef = useGridApiRef();
        const [selectionModel, setSelectionModel] = useState([]);

        const [isModalOpen, setIsModalOpen] = useState(false);
        const [initialRows, setInitialRows] = useState([]);
        const [hoveredCells, setHoveredCells] = useState([]);
        const [clickedCells, setClickedCells] = useState([]);
        const [isLoading, setIsLoading] = useState(false);

        const [pinned, setPinned] = useState({
            left: ['edit', 'select', 'history', 'lastName']
        });

        const [columnWidths, setColumnWidths] = useState({});

        const termCodes = useSelector(state => state.termsReducer?.terms ?? {});

        const trueUser = useSelector(state => state.AWSReducer.user);

        const isCourseActive =
            isTestingAllowed ||
            isUserAdmin(trueUser) ||
            Boolean(termCodes.CURRENT_TERM?.code === termCode);

        const onPinnedChange = pinned => {
            setPinned(pinned);
        };

        const handleColumnResize = params => {
            setColumnWidths(prevWidths => ({
                ...prevWidths,
                [params.colDef.field]: params.width
            }));
        };

        const loadPage = async () => {
            await pollingStatus(
                getStatus,
                data => data === false,
                SSA_STATUS_POLLING_INTERVAL
            );

            await fetchStudentsData();
        };

        const fetchStudentsData = async () => {
            try {
                setIsLoading(true);
                const data = await getData(ssa.students({termCode, crn}, true));

                setInitialRows(
                    data.students.map((student, index) => {
                        const latestUpdate = student.updates?.length
                            ? student.updates.reduce((latest, current) =>
                                  dayjs(current.timestamp).isAfter(
                                      dayjs(latest.timestamp)
                                  )
                                      ? current
                                      : latest
                              )
                            : {};

                        return {
                            id: index + 1,
                            ...student,
                            firstName: _.capitalize(
                                student.preferred_first_name ||
                                    student.given_name ||
                                    ''
                            ),
                            lastName: _.capitalize(student.surname || ''),
                            given_name: student.given_name,
                            preferred_first_name: student.preferred_first_name,
                            surname: student.surname,
                            reason:
                                student.reason || latestUpdate.reason || '-',
                            status:
                                student.status || latestUpdate.status || '-',
                            grade: student.grade || latestUpdate.grade || '-',
                            comment:
                                student.comment || latestUpdate.comment || ''
                        };
                    })
                );

                setIsLoading(false);
            } catch (err) {}
        };

        const getStatus = async () => {
            try {
                setIsLoading(true);

                const data = await getData(ssa.status({termCode, crn}, true));

                setIsLoading(data.isProcessing);

                return data.isProcessing;
            } catch (err) {}
        };

        useEffect(() => {
            loadPage();
        }, []);

        const allRowIds = initialRows.map(r => r.id);

        const handleSave = async data => {
            try {
                setIsLoading(true);

                const changedRowIds = new Set(getChangedRowIds()); // Use Set for faster lookup

                const formData = {
                    minCrn: crn,
                    termCode,
                    students: Object.entries(data.rows)
                        .filter(([id]) => changedRowIds.has(Number(id))) // Filter first for efficiency
                        .map(([, student]) => {
                            delete student.firstName;
                            delete student.lastName;

                            return Object.fromEntries(
                                Object.entries(student).map(([key, value]) => [
                                    key,
                                    value === '-' ? '' : value
                                ])
                            );
                        })
                };

                await postData(ssa.update(), formData, true);

                toast.success('Update request submitted!');
            } catch (err) {
                toast.error('Error submitting update request');
            } finally {
                methods.reset();
                await loadPage();
            }
        };

        const setProgressToSatisfactory = rowId => {
            if (rowId && !selectionModel.length) {
                methods.setValue(`rows.${rowId}.status`, 'Satisfactory', {
                    shouldDirty: true
                });
            } else {
                // Update `status` for selected rows
                selectionModel.forEach(id => {
                    methods.setValue(`rows.${id}.status`, 'Satisfactory', {
                        shouldDirty: true
                    });
                });
            }

            setSelectionModel([]);
        };

        const handleMouseEnterCell = (rowId, field) => {
            // if rowId is in selectionModel => hover the same field for all selected rows
            if (selectionModel.includes(rowId) && selectionModel.length > 1) {
                // build an array of { id, field } for each selected row
                const newHoveredCells = selectionModel.map(selId => ({
                    id: selId,
                    field
                }));
                setHoveredCells(newHoveredCells);
            } else {
                // otherwise, just hover this single cell
                setHoveredCells([{id: rowId, field}]);
            }
        };

        const handleClickCell = (rowId, field) => {
            // if rowId is in selectionModel => hover the same field for all selected rows
            if (selectionModel.includes(rowId) && selectionModel.length > 1) {
                // build an array of { id, field } for each selected row
                const newClickedCells = selectionModel.map(selId => ({
                    id: selId,
                    field
                }));
                setClickedCells(newClickedCells);
            } else {
                // otherwise, just hover this single cell
                setClickedCells([{id: rowId, field}]);
            }
        };

        const handleMouseLeaveCell = (rowId, field) => {
            setHoveredCells([]);
            setClickedCells([]);
        };

        const defaultValues = {
            bulkEdit: {
                reason: '',
                status: '',
                grade: '',
                comment: ''
            },
            rows: {}
        };

        const methods = useForm({
            defaultValues,
            mode: 'onTouched',
            values: _.merge(defaultValues, {
                rows: initialRows.reduce((acc, row) => {
                    acc[row.id] = {
                        pidm: row.student_pidm,
                        midas: row.midas,
                        reason: row.reason || '-',
                        status: row.status || '-',
                        grade: row.grade || '-',
                        comment: row.comment || '',
                        firstName: row.firstName,
                        lastName: row.lastName,
                        given_name: row.given_name,
                        surname: row.surname,
                        preferred_first_name: row.preferred_first_name,
                        crn: row.crn
                    };
                    return acc;
                }, {})
            })
        });

        const watchedRows = useWatch({
            control: methods.control,
            name: 'rows'
        });

        const computedRows = initialRows.map(origRow => {
            const formRow = watchedRows[origRow.id] || {};
            return {
                ...origRow,
                ...formRow,
                id: origRow.id
            };
        });

        const {isDirty, errors} = methods.formState;

        const getChangedRowIds = () => {
            const currentRows = methods.getValues('rows');
            const changedRowIds = Object.keys(currentRows)
                .filter(id => {
                    const initial = initialRows.find(
                        row => row.id === parseInt(id)
                    );
                    const current = currentRows[id];
                    return (
                        initial.reason !== current.reason ||
                        initial.status !== current.status ||
                        initial.grade !== current.grade ||
                        initial.comment !== current.comment
                    );
                })
                .map(id => parseInt(id));

            return changedRowIds;
        };

        const getUnchangedRowIds = () => {
            return allRowIds.filter(id => !getChangedRowIds().includes(id));
        };

        const isSaveAllowed =
            _.isEmpty(errors) && isDirty && !isLoading && isCourseActive;

        return (
            <FormProvider {...methods}>
                <Box display="flex" justifyContent="end" paddingBottom={2}>
                    <Button
                        className="myOdu__button primary"
                        size="small"
                        onClick={methods.handleSubmit(handleSave)}
                        disabled={!isSaveAllowed}
                    >
                        Submit Progress
                    </Button>
                </Box>

                <BulkUpdateModal
                    methods={methods}
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    selectionModel={selectionModel}
                    setSelectionModel={setSelectionModel}
                />

                <Box className="dataTable">
                    <Box className="outer">
                        <PortalDataGridPro
                            className="myOdu__resultTable inner"
                            isLoading={isLoading}
                            apiRef={apiRef}
                            rows={computedRows}
                            columns={getColumns({
                                methods,
                                allRowIds,
                                selectionModel,
                                setSelectionModel,
                                getChangedRowIds,
                                getUnchangedRowIds,
                                setIsModalOpen,
                                setProgressToSatisfactory,
                                hoveredCells,
                                clickedCells,
                                handleMouseEnterCell,
                                handleMouseLeaveCell,
                                handleClickCell,
                                columnWidths,
                                isEditable: !isLoading && isCourseActive
                            })}
                            getRowId={row => row.id}
                            getRowHeight={() => 'auto'}
                            pagination
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 20,
                                        page: 0
                                    }
                                }
                            }}
                            hideFooterSelectedRowCount
                            pinnedColumns={props.isSmall ? {} : pinned}
                            onPinnedColumnsChange={onPinnedChange}
                            onColumnWidthChange={handleColumnResize}
                            columnVisibilityModel={{
                                edit: !props.isSmall && isCourseActive,
                                select: isCourseActive
                            }}
                            disableRowSelectionOnClick
                            slotProps={{
                                loadingOverlay: {
                                    noRowsVariant: 'skeleton'
                                }
                            }}
                            slots={{
                                noRowsOverlay: () => {
                                    return (
                                        <Box
                                            mt={1}
                                            display="flex"
                                            justifyContent="center"
                                        >
                                            <Typography
                                                variant="p"
                                                component="p"
                                            >
                                                {'No student records found.'}
                                            </Typography>
                                        </Box>
                                    );
                                }
                            }}
                        />
                    </Box>
                </Box>
                <UnsavedChangesModal methods={methods} />
                <FooterActionBar
                    selectionModel={selectionModel}
                    setSelectionModel={setSelectionModel}
                    actionComponents={
                        <>
                            <Button
                                variant="outlined"
                                className="myOdu__button secondary"
                                onClick={() => setIsModalOpen(!isModalOpen)}
                                disabled={isLoading || !isCourseActive}
                            >
                                Bulk Edit
                            </Button>

                            <Button
                                variant="outlined"
                                className="myOdu__button secondary"
                                onClick={() => setProgressToSatisfactory()}
                                disabled={isLoading || !isCourseActive}
                            >
                                Set Progress to Satisfactory
                            </Button>
                        </>
                    }
                />
            </FormProvider>
        );
    }
);
