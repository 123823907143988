import './App.css';
import React, {Component} from 'react';
import {CssBaseline, ThemeProvider, Link} from '@mui/material';
import theme from './theme';
import {identifyUser} from './AWS';
import {connect} from 'react-redux';
import {saveUser} from './AWS/actions';
import {
    saveIsOutOfSync,
    savePreferences,
    saveSetup
} from './components/SetUp/actions';
import {storePreferences} from './components/SetUp';
import {getData} from './DataAccessLayer';
import {infoTips, myPreferences} from './DataAccessLayer/services';
import PortalSnackbarProvider from './components/common/PortalSnackbarProvider';
import {Hub} from 'aws-amplify';
import {RouterProvider} from 'react-router-dom';
import {
    isSameAppsVersion,
    isSameWidgetsVersion,
    updateApps,
    updateWidgetsOrder
} from './components/SetUp/utils';
import GoogleAnalytics from './GoogleAnalytics';
import {saveImpersonation} from './components/Impersonation/actions';
import {getUser, isUserAdmin} from './components/Layout/utils';
import router from './components/Layout/Router';
import PortalPageLoader from './components/common/PortalPageLoader';
import {LicenseInfo} from '@mui/x-license-pro';
import CognitoError from './components/Landing/CognitoError';
import {InfoTip} from './components/common/InfoTip';
import {
    INFOTIPS_DISPLAY_PROBABILTY,
    DEFAULT_DISPLAY_OPTIONS,
    INFOTIPS_DISPLAY_NUMBER
} from './components/common/constants';
import _, {sampleSize} from 'lodash';

class App extends Component {
    state = {
        showAuthTestPanel: true,
        showImpersonationPanel: true,
        isLoading: true,
        eventListener: null,
        infoTips: []
    };

    componentDidMount() {
        this.identifyUser().then(() => {
            //If Object consists user details start loading preferences
            if (Object.keys(this.props.user).length > 0) {
                this.loadUserPreferences();
            } else {
                //Else Mark is Loading to false
                this.toggleLoading(false);
            }
        });
        this.checkImpersonation();
        GoogleAnalytics();
        this.activateMUILicense();

        // Subscribe to listen to the events of user sign-in
        this.setState({eventListener: Hub.listen('auth', this.listener)});

        this.loadInfoTips();
        // Check for the target elements periodically after login
    }

    componentWillUnmount() {
        // Unsubscribe to listen to the events of user sign-in
        this.state.eventListener();
    }

    identifyUser = async () => {
        const user = await identifyUser();
        isUserAdmin(user) && console.log(user);
        this.props.saveUser(user);
        // this.toggleLoading();
    };

    listener = ({payload: {event, data}}) => {
        switch (event) {
            // Listens to 'customOAuthState' event to receive the path dtails of the page that  the user has requested for before the login. 'data' contains the path details of the page that we should be redirecting the user to immediately after the successful login. Example: 'data' can be '/checklists', '/statusAlerts' etc.
            case 'customOAuthState': {
                window.location = data;
                break;
            }
        }
    };

    //Θ(1) Loads all user prederences from backend
    loadUserPreferences = () => {
        getData(
            myPreferences +
                '/' +
                getUser(this.props.user, this.props.impersonation).midas
        )
            .then(result => {
                if (result && result.Widgets && result.Apps) {
                    //Flag to check if there is any update in preferences object
                    let isOutOfSync = false;
                    if (!isSameWidgetsVersion(result?.Widgets)) {
                        const user = getUser(
                            this.props.user,
                            this.props.impersonation
                        );
                        let widgets = updateWidgetsOrder(result?.Widgets, user);
                        result.Widgets = widgets;
                        isOutOfSync = true;
                    }
                    if (!isSameAppsVersion(result?.Apps)) {
                        result.Apps = updateApps(
                            result?.Apps,
                            getUser(this.props.user, this.props.impersonation)
                        );
                        isOutOfSync = true;
                    }
                    //Check is display options is present.
                    if (!result.DisplayOptions) {
                        result.DisplayOptions = {
                            ...result.DisplayOptions,
                            ...DEFAULT_DISPLAY_OPTIONS
                        };
                        isOutOfSync = true;
                    }

                    //If there is any update then save it on backend
                    if (isOutOfSync) {
                        storePreferences(result, this.props);
                    }
                    //isOutOfSync gets true whenever there is any version update in Widgets/Apps
                    //We also set it to true when we have fetched a preference object from backend and there is no preference object stored in redux (redux is empty);
                    isOutOfSync =
                        isOutOfSync ||
                        Object.keys(this.props.preferences).length === 0;

                    this.props.savePreferences(result, isOutOfSync);
                } else {
                    //Save the result into setup to save it in preferences in future
                    this.props.saveSetup({
                        ...this.props.setup,
                        reset: result
                    });
                    this.props.savePreferences({});
                }
            })
            .catch(err => {
                console.log(err);
                //TODO: Set to default preferences layout
            })
            .finally(() => {
                this.toggleLoading(false);
            });
    };

    /**
     * Fetches and displays up to `INFOTIPS_DISPLAY_NUMBER` tooltips with a 20% chance.
     * Prioritizes tooltips marked as `isPrioritized`, and fills remaining slots with
     * non-prioritized ones. Updates the component's state with the selected tooltips.
     * Handles errors and logs them if the fetch fails.
     */
    loadInfoTips = async () => {
        const randomness = Math.random();

        // Check chance of fetching tooltips based on configured probability
        if (randomness < INFOTIPS_DISPLAY_PROBABILTY) {
            try {
                const data = await getData(infoTips, true);

                // Filter prioritized and non-prioritized tips
                const {prioritizedTips, nonPrioritizedTips} = data.reduce(
                    (acc, tip) => {
                        if (tip.isPrioritized) {
                            acc.prioritizedTips.push(tip);
                        } else {
                            acc.nonPrioritizedTips.push(tip);
                        }

                        return acc;
                    },
                    {
                        prioritizedTips: [],
                        nonPrioritizedTips: []
                    }
                );

                // Randomly select a subset of prioritized tips, up to the defined number (INFOTIPS_DISPLAY_NUMBER)
                const randomPrioritizedTips = _.sampleSize(
                    prioritizedTips,
                    INFOTIPS_DISPLAY_NUMBER
                );

                // Randomly select enough non-prioritized tips to fill the remaining slots, ensuring the total is equal to INFOTIPS_DISPLAY_NUMBER
                const randomNonPrioritizedTips = _.sampleSize(
                    nonPrioritizedTips,
                    INFOTIPS_DISPLAY_NUMBER - randomPrioritizedTips.length
                );

                // Combine the selected prioritized and non-prioritized tips, ensuring that the total count is no more than INFOTIPS_DISPLAY_NUMBER
                const selectedInfoTips = [
                    ...randomPrioritizedTips,
                    ...randomNonPrioritizedTips
                ];

                this.setState({infoTips: selectedInfoTips});
            } catch (err) {
                console.log(err);
            }
        }
    };

    checkImpersonation = () => {
        if (!this.props.user?.firstName) this.props.saveImpersonation({});
    };

    toggleLoading = (isLoading = !this.state.isLoading) => {
        this.setState({isLoading});
    };

    activateMUILicense() {
        LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE);
    }

    render() {
        const isLoggedIn = this.props.user?.firstName ? true : false;

        return (
            <React.Fragment>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <PortalSnackbarProvider />

                    {this.state.isLoading ? (
                        <PortalPageLoader />
                    ) : (
                        <React.Fragment>
                            <RouterProvider router={router(isLoggedIn)} />
                            {this.state.infoTips.map(
                                ({
                                    id,
                                    openId,
                                    title,
                                    message,
                                    placement,
                                    closeId
                                }) => (
                                    <InfoTip
                                        id={id}
                                        key={openId}
                                        openTargetId={openId}
                                        message={message}
                                        placement={placement}
                                        closeTargetId={closeId}
                                        title={title}
                                    />
                                )
                            )}
                        </React.Fragment>
                    )}

                    <CognitoError error={this.props.error} />
                </ThemeProvider>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        isSideNav: state.headerReducer.isSideNav,
        user: state.AWSReducer.user,
        showAuthTestPanel: state.showAuthTestPanel,
        isImpersonating:
            state.impersonationReducer.impersonation?.isImpersonating ?? false,
        impersonation: state.impersonationReducer.impersonation,
        setup: state.setupReducer.setup,
        preferences: state.preferencesReducer.preferences
    };
};

const mapDispatchToProps = dispatch => ({
    saveUser: user => dispatch(saveUser(user)),
    savePreferences: (preferences, isOutOfSync) =>
        dispatch(savePreferences(preferences, isOutOfSync)),
    saveImpersonation: impersonation =>
        dispatch(saveImpersonation(impersonation)),
    saveSetup: setup => dispatch(saveSetup(setup))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
