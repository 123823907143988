export const PageDetails = {
    sam: {
        id: 'studentAdvisorManagement',
        title: 'Student Advisor Management',
        url: 'sam'
    },
    'advisor-summary': {
        id: 'advisorSummary',
        page: 'advisor-summary',
        title: 'Advisor Summary',
        url: 'advisor-summary'
    },
    'student-assigned': {
        id: 'advisorsAssignedStudents',
        page: 'student-assigned',
        title: 'Advisors Assigned Students',
        url: 'student-assigned'
    },
    'student-change-major': {
        id: 'studentsWithDifferentMajor',
        page: 'student-change-major',
        title: 'Students Who Have Selected a Different Major',
        url: 'student-change-major'
    },
    'student-unassigned': {
        id: 'unassignedStudents',
        page: 'student-unassigned',
        title: 'Unassigned Students',
        url: 'student-unassigned'
    },
    'student-missing-advisor-code': {
        id: 'studentsWithMissingAdvisorCode',
        page: 'student-missing-advisor-code',
        title: 'Students with Missing Advisor Code',
        url: 'student-missing-advisor-code',
        required: ['samPowerUserUp']
    }
};

export const AdvisorTypes = [
    {
        type: 'A',
        label: 'All'
    },
    {
        type: 'P',
        label: 'Primary'
    },
    {
        type: 'S',
        label: 'Secondary'
    }
];

export const DefaultDepartment = {
    STVDEPT_CODE: 'All',
    STVDEPT_DESC: '',
    STVDEPT_INFO: ''
};

export const SiteCodeCampusMapping = {
    100: 'On Campus',
    500: 'Online - In VA',
    700: 'Online - Out of State',
    '8OU': 'International'
};
