import PortalTooltip from '../../common/PortalTooltip';
import {faCheckCircle, faCircleXmark} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import _ from 'lodash';

export const getColumns = (isAdmin = false) => {
    let columns = [
        {
            field: 'STATUS',
            headerName: 'Job Status',
            width: 100,
            renderCell: params => {
                const error = params.row.ERROR;
                const isError = Boolean(error);
                return (
                    <PortalTooltip title={isError ? 'Error' : 'Success'}>
                        <FontAwesomeIcon
                            size="lg"
                            icon={isError ? faCircleXmark : faCheckCircle}
                            className={
                                isError ? 'myOdu__error' : 'myOdu__success'
                            }
                        />
                    </PortalTooltip>
                );
            }
        },
        {
            field: 'LAST_NAME',
            headerName: 'Last Name',
            width: 100,
            renderCell: params => params.row.STUDENT?.lastName ?? ''
        },
        {
            field: 'FIRST_NAME',
            headerName: 'First Name',
            width: 100,
            renderCell: params => params.row.STUDENT?.firstName ?? ''
        },
        {
            field: 'MIDAS',
            headerName: 'MIDAS',
            width: 100,
            renderCell: params => params.row.STUDENT?.midas ?? ''
        },
        {
            field: 'CRN',
            headerName: 'CRN',
            width: 70,
            renderCell: params => params.row.STUDENT?.crn ?? ''
        },
        {
            field: 'PROGRESS_REPORT',
            headerName: 'Status',
            width: 160,
            renderCell: params => params.row.STUDENT?.status ?? ''
        },
        {
            field: 'ALERT_STATUS',
            headerName: 'Explanation/Reason',
            width: 300,
            renderCell: params => params.row.STUDENT?.reason ?? ''
        },
        {
            field: 'GRADE',
            headerName: 'Grade',
            width: 90,
            renderCell: params => params.row.STUDENT?.grade ?? ''
        },
        {
            field: 'COMMENT',
            headerName: 'Comment',
            width: 450,
            renderCell: params => params.row.STUDENT?.comment ?? ''
        }
    ];

    if (isAdmin) {
        columns.push({
            field: 'ERROR',
            headerName: 'Error',
            width: 300,
            valueGetter: (params, row) =>
                _.isPlainObject(row.ERROR) ? row.ERROR.message : row.ERROR
        });
    }

    return columns;
};
