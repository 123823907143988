import React, {Component} from 'react';
import {
    Stack,
    Typography,
    Box,
    Button,
    Link,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@mui/material';
import {Time} from '../../common/MomentTime';
import {FormTextArea} from '../../common/Form/FormTextArea';
import {FormButton} from '../../common/Form/FormButton';
import ContactCard from '../../common/ContactCard';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faChevronDown,
    faFaceWorried,
    faFaceFrownSlight,
    faFaceMeh,
    faFaceSmile,
    faFaceSmileHearts,
    faMehBlank
} from '@fortawesome/pro-light-svg-icons';

class FeedbackDetails extends Component {
    state = {
        internalNote: '',
        isNoteEditable: false
    };

    componentDidMount() {
        this.setState({internalNote: this.props.data?.note || ''});
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.data?.note !== this.props.data?.note ||
            prevProps.data?.timestamp !== this.props.data?.timestamp
        ) {
            this.setState({internalNote: this.props.data?.note || ''});
        }
    }

    toggleNoteEditability = () => {
        this.setState({isNoteEditable: !this.state.isNoteEditable});
    };

    convertRatingToImage(rating) {
        switch (rating) {
            case 0:
                return (
                    <FontAwesomeIcon
                        id="ratingIcon"
                        icon={faFaceWorried}
                        fixedWidth
                        size="xl"
                    />
                );
            case 1:
                return (
                    <FontAwesomeIcon
                        id="ratingIcon"
                        icon={faFaceFrownSlight}
                        fixedWidth
                        size="xl"
                    />
                );
            case 2:
                return (
                    <FontAwesomeIcon
                        id="ratingIcon"
                        icon={faFaceMeh}
                        fixedWidth
                        size="xl"
                    />
                );
            case 3:
                return (
                    <FontAwesomeIcon
                        id="ratingIcon"
                        icon={faFaceSmile}
                        fixedWidth
                        size="xl"
                    />
                );
            case 4:
                return (
                    <FontAwesomeIcon
                        id="ratingIcon"
                        icon={faFaceSmileHearts}
                        fixedWidth
                        size="xl"
                    />
                );
            default:
                return (
                    <FontAwesomeIcon
                        id="ratingIcon"
                        icon={faMehBlank}
                        fixedWidth
                        size="xl"
                    />
                );
        }
    }

    render() {
        const {data} = this.props;
        const {
            midas,
            bugDetail,
            changeDetail,
            ideaDetail,
            metadata,
            otherDetail,
            pageUrl,
            rating,
            timestamp,
            note
        } = data || {};

        const {internalNote} = this.state;

        return (
            <div>
                <Typography component="h3"> Details </Typography>

                {/* <Box className="contactCard"> */}
                {/* //TODO: enable later when wired in */}
                {/* <ContactCard 
                        data = {{
                            id: midas,
                            firstName: "FirstName",
                            lastName: "LastName",
                            email: "blah@odu.edu",
                            department: "Some Major",
                            img: "",
                            directoryLink: "https://student360.odu.edu/dashboard/student/" + midas
                        }}
                    /> */}
                {/* </Box>      */}

                <Stack spacing={1}>
                    <Stack direction="row">
                        <Typography
                            component="label"
                            className="myOdu__label"
                            sx={{mr: 1}}
                        >
                            User
                        </Typography>
                        <Typography component="p">{midas}</Typography>
                    </Stack>

                    <Stack direction="row">
                        <Typography
                            component="label"
                            className="myOdu__label"
                            sx={{mr: 1}}
                        >
                            Time Submitted
                        </Typography>
                        <Typography component="p">
                            {timestamp
                                ? Time.University(timestamp).format(
                                      'M/DD/YYYY h:mm:ss A'
                                  )
                                : '-'}
                        </Typography>
                    </Stack>

                    <Stack direction="row" sx={{mb: 2}}>
                        <Typography
                            component="label"
                            className="myOdu__label"
                            sx={{mr: 1}}
                        >
                            Rating
                        </Typography>
                        <Typography component="p">
                            {rating ? rating : '-'}
                        </Typography>
                    </Stack>

                    {(pageUrl || bugDetail) && (
                        <Box className="section">
                            <Typography component="h4" className="bug">
                                {' '}
                                Bug Details{' '}
                            </Typography>

                            <Stack direction="row" sx={{my: 1}}>
                                <Typography className="pageUrl header">
                                    Page URL
                                </Typography>
                                <Typography className="pageUrl">
                                    {pageUrl ? pageUrl : '-'}
                                </Typography>
                            </Stack>

                            <Typography component="p">
                                {bugDetail ? bugDetail : '-'}
                            </Typography>
                        </Box>
                    )}

                    {ideaDetail && (
                        <Box className="section">
                            <Typography component="h4" className="idea">
                                {' '}
                                Idea Details{' '}
                            </Typography>
                            <Typography component="p">
                                {ideaDetail ? ideaDetail : '-'}
                            </Typography>
                        </Box>
                    )}

                    {changeDetail && (
                        <Box className="section">
                            <Typography component="h4" className="change">
                                {' '}
                                Change Details{' '}
                            </Typography>
                            <Typography component="p">
                                {changeDetail ? changeDetail : '-'}
                            </Typography>
                        </Box>
                    )}

                    {otherDetail && (
                        <Box className="section">
                            <Typography component="h4" className="other">
                                {' '}
                                Other Details{' '}
                            </Typography>
                            <Typography component="p">
                                {otherDetail ? otherDetail : '-'}
                            </Typography>
                        </Box>
                    )}

                    {/* <Box className="section">
                    <Typography component="h4" className='other'> MetaData </Typography>
                    <Typography component="p">
                        <div>
                            <pre>{JSON.stringify(metadata, null, 2)}</pre>
                        </div>
                    </Typography>
                </Box> */}

                    {metadata && (
                        <Accordion
                            className="section myOdu__accordion"
                            elevation={0}
                        >
                            <AccordionSummary
                                expandIcon={
                                    <FontAwesomeIcon
                                        icon={faChevronDown}
                                        // onClick={() => this.toggleExpand()}
                                        fixedWidth
                                    />
                                }
                                sx={{flexDirection: 'row-reverse', flexGrow: 1}}
                            >
                                <Typography component="h4" className="metadata">
                                    {' '}
                                    MetaData{' '}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    <pre>
                                        {JSON.stringify(metadata, null, 2)}
                                    </pre>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    )}

                    <Box className="note" sx={{mb: 4}}>
                        <Stack>
                            <Typography
                                component="label"
                                className="myOdu__label"
                            >
                                Admin Note
                            </Typography>

                            {!this.state.isNoteEditable ? (
                                <Typography variant="small">
                                    {internalNote ? (
                                        <Typography component="p">
                                            {internalNote}
                                            <Link
                                                className="cursor-pointer ms-2"
                                                underline="always"
                                                onClick={
                                                    this.toggleNoteEditability
                                                }
                                            >
                                                Edit Note
                                            </Link>
                                        </Typography>
                                    ) : (
                                        <Typography component="p">
                                            {' '}
                                            No notes found.{' '}
                                            <Link
                                                className="cursor-pointer ms-2"
                                                underline="always"
                                                onClick={
                                                    this.toggleNoteEditability
                                                }
                                            >
                                                Add Note
                                            </Link>
                                        </Typography>
                                    )}
                                </Typography>
                            ) : (
                                <>
                                    <FormTextArea
                                        inputGridProps={{xs: 12}}
                                        maxChars={1000}
                                        value={internalNote}
                                        onChange={e =>
                                            this.setState({internalNote: e})
                                        }
                                        disabled={!(midas && timestamp)}
                                    />
                                    <Box display="flex" justifyContent="right">
                                        <FormButton
                                            className="secondary"
                                            label="Add Note"
                                            onClick={() => {
                                                this.toggleNoteEditability();
                                                this.props.onSaveNote({
                                                    midas,
                                                    timestamp,
                                                    note: internalNote
                                                });
                                            }}
                                            disabled={
                                                !internalNote ||
                                                !(midas && timestamp)
                                            }
                                        />
                                    </Box>
                                </>
                            )}
                        </Stack>
                    </Box>
                </Stack>
            </div>
        );
    }
}

export default FeedbackDetails;
