import React from 'react';
import {RowEdit} from '../common/DataTable/RowEdit';
import {HeaderMenu} from '../common/DataTable/HeaderMenu';
import {RowCheckbox} from '../common/DataTable/RowCheckbox';
import {CellControlledTextArea} from '../common/DataTable/CellTextArea';
import {CellControlledDropDown} from '../common/DataTable/CellDropdown';

import {gradeOptions, statusOptions, getReasonOptions} from './options';
import {ChangesHistory} from './ChangesHistoryAction';
import {
    validateComment,
    validateGrade,
    validateReason,
    validateStatus
} from './utils';

export const getColumns = ({
    methods,
    allRowIds,
    selectionModel,
    setSelectionModel,
    getChangedRowIds,
    getUnchangedRowIds,
    setProgressToSatisfactory,
    hoveredCells,
    clickedCells,
    handleMouseEnterCell,
    handleMouseLeaveCell,
    handleClickCell,
    columnWidths,
    isEditable
}) => [
    {
        field: 'edit',
        headerName: '',
        width: columnWidths.edit || 65,
        sortable: false,
        disableColumnMenu: true,
        resizable: false,
        cellClassName: 'edit-cell',
        headerClassName: 'edit-cell-header',
        renderCell: params => (
            <RowEdit
                rowId={params.row.id}
                actions={[
                    {
                        handler: setProgressToSatisfactory,
                        title: 'Set Progress to Satisfactory',
                        disabled: !isEditable
                    }
                ]}
            />
        )
    },
    {
        field: 'select',
        headerName: '',
        width: columnWidths.select || 65,
        sortable: false,
        renderHeader: () => (
            <HeaderMenu
                selectionModel={selectionModel}
                setSelectionModel={setSelectionModel}
                allRowIds={allRowIds}
                getChangedRowIds={getChangedRowIds}
                getUnchangedRowIds={getUnchangedRowIds}
            />
        ),
        renderCell: params => (
            <RowCheckbox
                rowId={params.row.id}
                selectionModel={selectionModel}
                setSelectionModel={setSelectionModel}
            />
        ),
        disableColumnMenu: true,
        resizable: false
    },
    {
        field: 'history',
        headerName: 'History',
        width: columnWidths.history || 70,
        sortable: false,
        renderCell: params => <ChangesHistory row={params.row} />,
        disableColumnMenu: true,
        resizable: false
    },
    {
        field: 'lastName',
        headerName: 'Last Name',
        width: columnWidths.lastName || 100
    },
    {
        field: 'firstName',
        headerName: 'First Name',
        width: columnWidths.firstName || 100
    },
    {field: 'midas', headerName: 'MIDAS', width: columnWidths.midas || 100},
    {
        field: 'crn',
        headerName: 'CRN',
        width: columnWidths.crn || 70
    },
    {
        field: 'status',
        headerName: 'Status',
        width: columnWidths.status || 300,
        renderCell: params => {
            const rowId = params.row.id;

            const isChangedRow = getChangedRowIds().includes(rowId);

            const statusValue = methods.getValues(`rows.${rowId}.status`);

            const {required, errorTextShortened} = validateStatus({
                statusValue,
                isChangedRow
            });

            const statusToAdd = {
                label: statusValue,
                value: statusValue,
                disabled: true
            };

            const mergedStatuses = new Set(
                statusOptions.map(option => option.value)
            ).has(statusValue)
                ? statusOptions
                : [...statusOptions, statusToAdd];

            return (
                <CellControlledDropDown
                    field="status"
                    params={params}
                    methods={methods}
                    options={mergedStatuses}
                    selectionModel={selectionModel}
                    hoveredCells={hoveredCells}
                    onMouseEnterCell={handleMouseEnterCell}
                    onMouseLeaveCell={handleMouseLeaveCell}
                    onClickedCell={handleClickCell}
                    rules={{
                        required,
                        errorTextShortened
                    }}
                    isEditable={isEditable}
                />
            );
        }
    },
    {
        field: 'reason',
        headerName: 'Explanation/Reason',
        width: columnWidths.reason || 340,
        renderCell: params => {
            const field = 'reason';
            const rowId = params.row.id;

            const isChangedRow = getChangedRowIds().includes(rowId);

            const isClickedCell = clickedCells.find(
                cell => cell.id === rowId && cell.field === field
            );

            const statusValue = methods.getValues(`rows.${rowId}.status`);

            const reasonValue = methods.getValues(`rows.${rowId}.reason`);

            const reasonOptions =
                getReasonOptions()[statusValue?.toLowerCase() || '-'];

            const {required, errorTextShortened} = validateReason({
                statusValue,
                reasonValue,
                isChangedRow,
                isClickedCell,
                reasonOptions
            });

            const reasonToAdd = {
                label: reasonValue,
                value: reasonValue,
                disabled: true
            };

            const mergedReasons = new Set(
                reasonOptions.map(option => option.value)
            ).has(reasonValue)
                ? reasonOptions
                : [...reasonOptions, reasonToAdd];

            return (
                <CellControlledDropDown
                    field={field}
                    params={params}
                    methods={methods}
                    options={mergedReasons}
                    selectionModel={selectionModel}
                    hoveredCells={hoveredCells}
                    onMouseEnterCell={handleMouseEnterCell}
                    onMouseLeaveCell={handleMouseLeaveCell}
                    onClickedCell={handleClickCell}
                    rules={{
                        required,
                        errorTextShortened
                    }}
                    isEditable={isEditable}
                    isDisabled={!statusValue || statusValue === '-'}
                />
            );
        }
    },
    {
        field: 'grade',
        headerName: 'Grade',
        width: columnWidths.grade || 90,
        renderCell: params => {
            const rowId = params.row.id;

            // Watch values for reason and comment
            const reasonValue = methods.getValues(`rows.${rowId}.reason`);

            const gradeValue = methods.getValues(`rows.${rowId}.grade`);

            const {required, errorTextShortened} = validateGrade({
                reasonValue,
                gradeValue
            });

            return (
                <CellControlledDropDown
                    field="grade"
                    params={params}
                    methods={methods}
                    options={gradeOptions}
                    selectionModel={selectionModel}
                    hoveredCells={hoveredCells}
                    onMouseEnterCell={handleMouseEnterCell}
                    onMouseLeaveCell={handleMouseLeaveCell}
                    onClickedCell={handleClickCell}
                    rules={{
                        required,
                        errorTextShortened
                    }}
                    isEditable={isEditable}
                />
            );
        }
    },
    {
        field: 'comment',
        headerName: 'Comment',
        width: columnWidths.comment || isEditable ? 450 : 600,
        renderCell: params => {
            const rowId = params.row.id;

            // Watch values for reason and comment
            const reasonValue = methods.getValues(`rows.${rowId}.reason`);

            const commentValue = methods.getValues(`rows.${rowId}.comment`);

            const {required} = validateComment({commentValue, reasonValue});

            return (
                <CellControlledTextArea
                    field="comment"
                    params={params}
                    methods={methods}
                    selectionModel={selectionModel}
                    hoveredCells={hoveredCells}
                    onMouseEnterCell={handleMouseEnterCell}
                    onMouseLeaveCell={handleMouseLeaveCell}
                    onClickedCell={handleClickCell}
                    rules={{
                        required
                    }}
                    isEditable={isEditable}
                />
            );
        }
    }
];

export const getSubRowColumns = () => [
    {field: 'updatedAt', headerName: 'Date', width: '20%'},
    {field: 'status', headerName: 'Status', width: '30%'},
    {field: 'reason', headerName: 'Explanation/Reason', width: '30%'},
    {field: 'grade', headerName: 'Grade', width: '10%'},
    {field: 'comment', headerName: 'Comment', width: '40%'}
];
