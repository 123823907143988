export const DayMapping = {
    M: 'Monday',
    T: 'Tuesday',
    W: 'Wednesday',
    R: 'Thursday',
    F: 'Friday',
    S: 'Saturday',
    U: 'Sunday'
};
export const zoomDevEmail = 'jtate@online-pprd.odu.edu';
export const meetingStartsWithin = 30;
export const monthsList = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];
export const FACULTY_EMAIL_CODE = 'ODUF';
export const STUDENT_EMAIL_CODE = 'ODUS';
