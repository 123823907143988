import React from 'react';
import { useSelector } from 'react-redux';
import Layout from '../';
import { isUserAdmin, isUserPowerUser } from '../utils';
import Unauthorized from './Unauthorized';

export default function PowerRoute(props) {
  // const trueUser = useSelector(state => state.AWSReducer.user);
  const user = useSelector(state => state.impersonationReducer.impersonation?.isImpersonating ? state.impersonationReducer.impersonation : state.AWSReducer.user);

  const isPower = isUserPowerUser(user)
  const isAdmin = isUserAdmin(user);

  // console.log(trueUser, ' : ', isAdmin);
  return (isPower || isAdmin) ? props.element : <Unauthorized />
}