export const getPageDetails = ({termCode, crn}) => ({
    studentprogress: {
        id: 'studentProgressNotifications',
        title: 'Student Progress Notifications',
        page: `/studentprogress/${termCode}/${crn}`
    },
    report: {
        id: 'recordsReport',
        title: 'View Processing Report',
        page: `/studentprogress/report/${termCode}/${crn}`
    }
});
