import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Box, Container} from '@mui/material';
import PageHeader from '../PageHeader';
import StudentDetails from '../StudentDetails';
import SAMFilter from '../SAMFilter';
import {PageDetails} from '../config';
import {getColumns} from './utils';
import {getData} from '../../../DataAccessLayer';
import {sam} from '../../../DataAccessLayer/services';

export const AdvisorSummary = () => {
    const id = 'advisorSummary';
    const [title, setTitle] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [advisors, setAdvisors] = useState([]);
    const [activeDepartment, setActiveDepartment] = useState({});
    const [isSearchResult, setIsSearchResult] = useState(false);
    const [actionMenuItems, setActionMenuItems] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const termCodes = useSelector(state => state.termsReducer?.terms ?? {});

    useEffect(() => {
        const page = Object.values(PageDetails).find(page => page.id === id);
        if (page) setTitle(page.title);
    }, []);

    useEffect(() => {
        setActionMenuItems([
            {
                id: `${id}__menuItem_refresh`,
                label: 'Refresh',
                onClick: () => loadAdvisorSummary(activeDepartment)
            }
        ]);
    }, [activeDepartment]);

    const loadAdvisorSummary = async (department = activeDepartment) => {
        setIsLoading(true);
        setAdvisors([]);
        try {
            const advisors = await getData(
                sam.advisorSummary(department.STVDEPT_CODE),
                true
            );
            setAdvisors(advisors);
            setErrorMessage('');
        } catch (err) {
            setErrorMessage(
                'We’re currently experiencing an issue retrieving student data. Please try again later.'
            );
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    const searchAdvisorsByDepartment = ({department}) => {
        setActiveDepartment(department);
        setIsSearchResult(true);
        loadAdvisorSummary(department);
    };

    return (
        <div id={`${id}__div_pageWrapper`} className={`myOdu__${id}`}>
            <Container
                id={`${id}__container_pageWrapper`}
                className="myOdu__container secondaryPage"
            >
                <PageHeader id={id} title={title} isSubPage />
                <Box id={`${id}__box_wrapper`} className="wrapper">
                    <SAMFilter
                        id={id}
                        isDepartmentCode
                        isDepartmentRequired
                        departmentsLength={{xs: 12, xl: 5}}
                        primaryButtonText="Search"
                        onPrimary={searchAdvisorsByDepartment}
                        disablePrimaryButton={isLoading}
                        primaryButtonLength={{xs: 12, xl: 1}}
                        widgetName={title}
                    />
                    {isSearchResult && (
                        <StudentDetails
                            id={`${id}Details`}
                            students={advisors}
                            columns={getColumns({termCodes})}
                            actionMenuItems={actionMenuItems}
                            isHideCheckbox
                            noStudentMessage={
                                errorMessage || (
                                    <>
                                        There are no advisor summary in{' '}
                                        <strong>
                                            {activeDepartment.STVDEPT_DESC}
                                        </strong>
                                        .
                                    </>
                                )
                            }
                            message={`Advisor Summary in ${activeDepartment.STVDEPT_DESC}`}
                            isLoading={isLoading}
                        />
                    )}
                </Box>
            </Container>
        </div>
    );
};
