import React, {useEffect, useState} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Chip,
    Grid,
    LinearProgress,
    Stack,
    Typography
} from '@mui/material';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown} from '@fortawesome/pro-light-svg-icons';
import {Time} from '../../common/MomentTime';
import {ReportSummary} from './ReportSummary';
import PortalDataGridPro from '../../common/PortalDataGridPro';
import {useSelector} from 'react-redux';
import {isUserAdmin} from '../../Layout/utils';
import {getColumns} from './ReportColumns';
import {getData} from '../../../DataAccessLayer';
import {ssa} from '../../../DataAccessLayer/services';
import {WithSmallScreen} from '../../common/WithSmallScreen';

export const ReportCard = WithSmallScreen(
    ({record, cachedData, addCachedData, refresh, isRetriable, ...props}) => {
        const dateFormat = 'LL';
        const smallScreenDateFormat = 'MMM D, YYYY';
        const [isExpanded, setIsExpand] = useState(props.isExpanded);
        const [columns, setColumns] = useState([]);
        const [jobs, setJobs] = useState([]);
        const [isLoading, setIsLoading] = useState(false);

        const user = useSelector(state => state.AWSReducer.user);

        useEffect(() => {
            if (isExpanded) {
                getJobDetails();
            }

            loadColumns();
        }, [isExpanded, record]);

        const loadColumns = () => {
            const isAdmin = isUserAdmin(user);

            setColumns(getColumns(isAdmin));
        };
        const getJobDetails = async () => {
            setIsLoading(true);

            const jobId = record?.CREATED_AT;

            const [termCode, crn] = record?.TERM_CODE_CRN?.split('_') ?? [];

            const cachedJobData = cachedData[jobId];

            if (cachedJobData) {
                setJobs(cachedJobData);

                setIsLoading(false);
            } else {
                try {
                    const data = await getData(
                        ssa.getJobDetails({
                            jobId,
                            termCode,
                            crn
                        })
                    );

                    const transformData = data.map(item => ({
                        ...item,
                        STUDENT: {
                            ...item.STUDENT,
                            firstName: _.capitalize(
                                item.STUDENT.preferred_first_name ||
                                    item.STUDENT.given_name
                            ),
                            lastName: _.capitalize(item.STUDENT.surname || '')
                        }
                    }));

                    setJobs(transformData);
                    addCachedData({jobId, data: transformData});
                } catch (err) {
                } finally {
                    setIsLoading(false);
                }
            }
        };

        const id = `ssaReport_${props.id}`;
        const jobId = record?.CREATED_AT.toString().slice(-5);

        return (
            <Accordion
                expanded={isExpanded}
                elevation={0}
                className="accordion"
                id={id + '__accordion'}
            >
                <AccordionSummary
                    onClick={() => {
                        setIsExpand(!isExpanded);
                    }}
                    expandIcon={
                        <Box
                            display={'flex'}
                            alignItems={'flex-start'}
                            justifyContent={'flex-start'}
                        >
                            <FontAwesomeIcon
                                icon={faChevronDown}
                                fixedWidth
                                id={id + '__chevron_accordionSummary'}
                            />
                        </Box>
                    }
                    id={id + '__accordionSummary'}
                    aria-controls={id + '__accordionDetails'}
                    sx={{
                        flexDirection: 'row-reverse',
                        flexGrow: 1,
                        '& .MuiAccordionSummary-expandIconWrapper': {
                            marginTop: '1rem',
                            alignSelf: 'flex-start' // Aligns the expandIcon itself to the top
                        }
                    }}
                    className="header"
                    style={{height: 'auto'}}
                    justifyContent="center"
                >
                    <Grid
                        container
                        direction={{xs: 'column', sm: 'column', md: 'row'}}
                    >
                        <Grid item>
                            <Typography
                                id={id + '__title'}
                                component="h3"
                                className="title"
                                sx={{marginLeft: '0.5rem !important'}}
                            >
                                {Time.University(record?.CREATED_AT).format(
                                    props.isSM
                                        ? smallScreenDateFormat
                                        : dateFormat
                                )}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Box
                                id={id + '__ssaReportMenu_wrapper'}
                                sx={{
                                    textAlign: {
                                        xs: 'left',
                                        sm: 'left',
                                        md: 'right'
                                    }
                                }}
                            >
                                <Stack
                                    direction={{
                                        xs: 'row',
                                        sm: 'row',
                                        md: 'row'
                                    }}
                                    alignItems={'center'}
                                    justifyContent={{
                                        xs: 'flex-start',
                                        md: 'flex-end'
                                    }}
                                >
                                    <Typography
                                        variant="p"
                                        component={'p'}
                                        whiteSpace={'nowrap'}
                                        sx={{marginLeft: '0.5rem'}}
                                    >
                                        {'Job - ' + jobId}
                                    </Typography>
                                    {/* {
                                            !props.isSM &&
                                            <Typography variant='p' component={'p'} whiteSpace={'nowrap'} sx={{marginLeft: 1}}>
                                                -
                                            </Typography>
                                        } */}
                                    <span>
                                        <Chip
                                            label={`${record?.SUCCESS_COUNT} of ${record.PROCESSED_COUNT} Updated`}
                                            sx={{marginLeft: 1}}
                                            size="small"
                                            color="default"
                                        />
                                    </span>
                                </Stack>
                            </Box>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails
                    id={id + '__accordionDetails'}
                    className="details"
                >
                    <Stack spacing={2}>
                        <ReportSummary
                            id={props.id}
                            record={record}
                            refresh={refresh}
                            isRetriable={isRetriable}
                        />

                        <PortalDataGridPro
                            className="myOdu__resultTable"
                            isLoading={isLoading}
                            columns={columns}
                            rows={jobs}
                            getRowId={row => row.PIDM_CREATED_AT}
                            initialState={{
                                pagination: {
                                    paginationModel: {pageSize: 20}
                                }
                            }}
                            pagination
                            slots={{
                                loadingOverlay: LinearProgress,
                                noRowsOverlay: () => {
                                    return (
                                        <Box
                                            sx={{
                                                margin: 1
                                            }}
                                        >
                                            <Typography
                                                variant="p"
                                                component="p"
                                            >
                                                {'No student records found.'}
                                            </Typography>
                                        </Box>
                                    );
                                }
                            }}
                        />
                    </Stack>
                </AccordionDetails>
            </Accordion>
        );
    }
);
