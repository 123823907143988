import {useLocation, useNavigate, useParams} from 'react-router-dom';

const WithRouter = Component => props => {
    return (
        <Component
            {...props}
            navigate={useNavigate()}
            location={useLocation()}
            params={useParams()}
        />
    );
};

export default WithRouter;
