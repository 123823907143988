import { Box, Button, FormControlLabel, Grid, Menu, MenuItem, Switch, Tab, Tabs, Typography } from '@mui/material';
import React, { Component } from 'react';
import { filterBadData, getSelectedApps } from '../../../MyApps/utils';
import { ICON_PATH } from '../../../common/constants';
import { AppConfigs } from '../../../SetUp/config';
import { connect } from 'react-redux';
import AppCard from './AppCard';
import { getSelectedAppsCount, toggleAppSelection } from '../../../SetUp/utils';
import { myPreferences } from '../../../../DataAccessLayer/services';
import { getData, putData } from '../../../../DataAccessLayer';
import { faCaretDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Categories from './Categories';
import { getUser } from '../../../Layout/utils';
import { savePreferences } from '../../../SetUp/actions';
import { deepCloneObject } from '../../../common/utils';

class FavoriteApps extends Component {

    state = {
        activeTab: 1,
        selectedApps: [],
        selectedAppsCount: 0,
        loadingApps: {},
        anchor: null,
        isOpen: false,
        isShowODU: true,
        isShowEVMS: true
    }

    componentDidMount() {
        const selectedApps = getSelectedApps(this.props.preferences?.Apps?.Apps);
        const selectedAppsCount = getSelectedAppsCount(this.props.preferences?.Apps?.Apps || {})
        this.setState({
            selectedApps,
            selectedAppsCount
        })
    }

    dropAnchor = (e) => {
        this.setState({anchor: e.currentTarget, isOpen: true})
    }
    
    pullAnchor = () => {
        this.setState({anchor: null, isOpen: false})
    }

    onCategoryChange = (event) => {
        this.setState({
            [event.target.name]: event.target.checked
        })
    }

    updateLoadingApps = (appId, isLoading) => {
        if(!appId)
            return
        const loadingApps = this.state.loadingApps;
        if(isLoading) {
            //add app to the object if its toggled and waiting for the backend call to finish
            loadingApps[appId] = true;
        } else {
            // Remove the appid from the object when we receive a response for respective app from bankend.
            delete loadingApps[appId];
        }

        this.setState({
            loadingApps
        })
    }

    //Θ(1) Sets selectedAppsCount
    saveSelectedAppsCount = (selectedAppsCount) => {
        this.setState({
            selectedAppsCount
        })
    }

    //Θ(N) where N is the number of applications 
    //Updates the selection of the app
    onUpdateAppSelection = (app) => {
        this.updateLoadingApps(app.id, true);
        let preferences = deepCloneObject(this.props.preferences);
        const {apps, selectedAppsCount} = toggleAppSelection(preferences.Apps.Apps, app, this.state.selectedAppsCount);
        this.saveSelectedAppsCount(selectedAppsCount);
        preferences.Apps.Apps = apps;
        this.storePreferences(preferences, app.id);
    }

    //Θ(1) makes a call to backend to store preferences
    storePreferences = (preferences, appId) => {
        putData(myPreferences, {
            preferences,
            midas: getUser(this.props.user, this.props.impersonation).midas
        }, true)
        .then(_ => {
            this.props.savePreferences(preferences);
        })
        .catch(err => console.log(err))
        .finally(_ => {
            this.loadPreferences(appId)
        })
    }

    loadPreferences = (appId) => {
        getData(
            myPreferences +
                '/' +
                (getUser(this.props.user, this.props.impersonation).midas),
            true
        )
        .then(preferences => {
            this.props.savePreferences(preferences);
        })
        .catch(err => {
            console.log(err);
        })
        .finally(_ => {
            this.updateLoadingApps(appId, false)
        })
    }

    //Θ(1) checks if the application is already selected
    isAppSelected = (app) => {
        return this.props.preferences?.Apps?.Apps && app.id in this.props.preferences?.Apps?.Apps && this.props.preferences?.Apps?.Apps[app.id] > -1
    }

    //Θ(N) where N is the number of apps in AppsConfig
    getAppsToDisplay = () => {
        return AppConfigs.Apps.filter(app => {
            return (app.isODU && this.state.isShowODU || app.isEVMS && this.state.isShowEVMS) && !app.isDeleted
        })
    }

    render() {
        const apps = this.getAppsToDisplay();
        return <React.Fragment>
            <Typography component="h4" className="sr-only visibility-hidden">Set Favorites</Typography>
            <Grid container direction={'column'} style={{paddingTop: 8}} wrap='nowrap'>
                <Grid item container xs={12} justifyContent={'flex-end'} alignItems={'center'}>
                    <Grid item>
                        <Typography variant='small' component='p'>
                            Show
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Categories 
                            dropAnchor = {this.dropAnchor}
                            anchor = {this.state.anchor}
                            isOpen = {this.state.isOpen}
                            pullAnchor = {this.pullAnchor}
                            onCategoryChange = {this.onCategoryChange}
                            isShowEVMS = {this.state.isShowEVMS}
                            isShowODU = {this.state.isShowODU}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={10}>
                    <Box className="favoriteApps" >
                        {
                            apps.length ?
                            apps.map((app) => {
                                return <AppCard 
                                    app={app}
                                    showHeart
                                    isSelected = {this.isAppSelected(app)}
                                    data={{icon: ICON_PATH + app.icon}}
                                    onUpdateAppSelection = {this.onUpdateAppSelection}
                                    loadingApps = {this.state.loadingApps}
                                />
                            }) : 
                            <Box
                                sx={{
                                    width: '100%',
                                    padding: 8
                                }}
                            >
                                <Grid container justifyContent={'center'} alignItems={'center'}>
                                    <Grid item>
                                        <Typography>No results to display. Choose </Typography>
                                    </Grid>
                                    <Grid item style={{marginTop: '2px'}}>
                                        <Categories 
                                            dropAnchor = {this.dropAnchor}
                                            anchor = {this.state.anchor}
                                            isOpen = {this.state.isOpen}
                                            pullAnchor = {this.pullAnchor}
                                            onCategoryChange = {this.onCategoryChange}
                                            isShowEVMS = {this.state.isShowEVMS}
                                            isShowODU = {this.state.isShowODU}
                                            onMenuClick = {this.pullAnchor}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        }
                    </Box>
                </Grid>
            </Grid>
        </React.Fragment>
    }
}

const mapStateToProps = (state) => {
    return {
        preferences: state.preferencesReducer.preferences,
        user: state.AWSReducer.user,
        isImpersonating: state.impersonationReducer.impersonation?.isImpersonating ?? false,
        impersonation: state.impersonationReducer.impersonation
    }
  }
  
const mapDispatchToProps = (dispatch) => ({
    saveSetup: (setup) => dispatch(saveSetup(setup)),
    savePreferences: (preferences) => dispatch(savePreferences(preferences))
});

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteApps);