import React from 'react';
import {
    Box,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
} from '@mui/material';

import dayjs from 'dayjs';
import {Time} from '../MomentTime';

export const PortalTable = ({row, subRowColumns = []}) => {
    const {subRows = []} = row;

    return (
        <TableContainer
            component={Box}
            sx={{padding: 2, height: 300, overflow: 'auto'}}
        >
            <Table size="small">
                <TableHead>
                    <TableRow>
                        {subRowColumns.map(col => (
                            <TableCell
                                key={col.field}
                                sx={{fontWeight: 'bold'}}
                            >
                                {col.headerName}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {subRows.length > 0 ? (
                        subRows.map((subRow, index) => (
                            <TableRow key={index}>
                                {subRowColumns.map(col => (
                                    <TableCell key={col.field}>
                                        {typeof subRow[col.field] ===
                                            'string' &&
                                        dayjs(subRow[col.field]).isValid()
                                            ? Time.University(
                                                  subRow[col.field]
                                              ).format('MM/DD/YYYY h:mm:ss A')
                                            : subRow[col.field] || ''}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell
                                colSpan={subRowColumns.length}
                                align="center"
                            >
                                No data available
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
