import * as React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Autocomplete, {autocompleteClasses} from '@mui/material/Autocomplete';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListSubheader from '@mui/material/ListSubheader';
import Popper from '@mui/material/Popper';
import {useTheme, styled} from '@mui/material/styles';
import {VariableSizeList} from 'react-window';
import Typography from '@mui/material/Typography';

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
    const {data, index, style} = props;
    const dataSet = data[index];
    const inlineStyle = {
        ...style,
        top: style.top + LISTBOX_PADDING
    };
    return (
        <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
            {dataSet[0].key}
        </Typography>
    );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(
    props,
    ref
) {
    const {children, ...other} = props;
    const itemData = [];
    children.forEach(item => {
        itemData.push(item);
        itemData.push(...(item.children || []));
    });
    const itemCount = itemData.length;
    const itemSize = 36;

    const getChildSize = child => {
        if (child.hasOwnProperty('group')) {
            return 48;
        }

        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={index => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

class PortalAutoComplete extends React.Component {
    state = {};

    render() {
        return (
            <Autocomplete
                multiple={this.props.multiple}
                id={this.props.id}
                ListboxComponent={ListboxComponent}
                options={this.props.options}
                renderInput={params => (
                    <TextField
                        {...params}
                        id={this.props.id}
                        placeholder={this.props.placeholder}
                        inputProps={{
                            ...params.inputProps,
                            'aria-label': this.props.ariaLabel || ''
                        }}
                    />
                )}
                renderOption={(props, option, state) => [
                    props,
                    option,
                    state.index
                ]}
                renderGroup={params => params}
                getOptionLabel={this.props.getOptionLabel}
                getOptionDisabled={this.props.getOptionDisabled}
                value={this.props.value}
                freeSolo
                forcePopupIcon
                onChange={this.props.onChange}
                fullWidth
                size="small"
                disabled={this.props.disabled}
                // loading = {this.props.options.length === 0}
                loading
                onBlur={this.props.onBlur}
                {...this.props.autocompleteProps}
            />
        );
    }
}

export default PortalAutoComplete;
