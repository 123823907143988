import React from 'react';
import {Stack, Typography, Box} from '@mui/material';
import {FormTextArea} from '../../common/Form/FormTextArea';
import {Time} from '../../common/MomentTime';

export const FrontendErrorLogDetails = ({data}) => {
    const {midas, error = '', lastSeen, count} = data || {};

    return (
        <div>
            <Typography component="h3"> Details </Typography>
            <Stack spacing={1}>
                {midas && (
                    <Stack direction="row">
                        <Typography
                            component="label"
                            className="myOdu__label"
                            sx={{mr: 1}}
                        >
                            User
                        </Typography>
                        <Typography component="p">{midas}</Typography>
                    </Stack>
                )}
                <Stack direction="row">
                    <Typography
                        component="label"
                        className="myOdu__label"
                        sx={{mr: 1}}
                    >
                        Last Seen
                    </Typography>
                    <Typography component="p">
                        {lastSeen
                            ? Time.University(lastSeen).format(
                                  'M/DD/YYYY h:mm:ss A'
                              )
                            : '-'}
                    </Typography>
                </Stack>
                {count && (
                    <Stack direction="row">
                        <Typography
                            component="label"
                            className="myOdu__label"
                            sx={{mr: 1}}
                        >
                            Count
                        </Typography>
                        <Typography component="p">{count}</Typography>
                    </Stack>
                )}

                <Box className="section">
                    <Typography component="h4" className="bug">
                        {' '}
                        Error
                    </Typography>
                    <Typography component="p">
                        <pre>
                            {typeof error === 'object'
                                ? JSON.stringify(error, null, 2)
                                : error}
                        </pre>
                    </Typography>
                </Box>
            </Stack>
        </div>
    );
};
