import React, {Component} from 'react';
import {Col, Row, Container} from 'reactstrap';
import {Typography, Snackbar} from '@mui/material';
import {BreakpointBoxes} from '../common/BreakpointBoxes';
import {connect} from 'react-redux';
import { WidgetConfigs} from '../SetUp/config';
import {SectionParams} from './config';
import Section from './Section';
import {saveIsOutOfSync, savePreferences} from '../SetUp/actions';
import {myPreferences} from '../../DataAccessLayer/services';
import {getData, putData} from '../../DataAccessLayer';
import {getUser, isUserAdmin, isUserMedical, isProduction} from '../Layout/utils';
import SetUp from '../SetUp';
import { getDefaultWidgetOrder } from '../SetUp/utils';
import { deepClone } from '@mui/x-data-grid/utils/utils';
import { arrayMove } from '@dnd-kit/sortable';
import { debounce } from 'lodash';
import { WIDGETS_THROTTLE_DELAY } from '../common/constants';
import { getWidgets } from './utils';
import EllucianCloudMigration from "./Banners/EllucianCloudMigration";

class Dashboard extends Component {

    state = {
        selectedSection: 0,
        selectedItem: {},
        //This is the lock to detect that we have an API call pending
        isDebounced: false,
        widgets: []
    };

    componentDidMount() {
        this.updateWidgets();
    }

    //A boolean flag isOutOfSync is in preference reducer which is set to true whenever preferences gets updated from App js
    //We need to update widgets state whenever isOutOfSync is true
    componentDidUpdate(prevProps) {
        if(this?.props?.isOutOfSync) {
            this.updateWidgets();
            this.props.saveIsOutOfSync(false)
        }
    }

    //θ(N) wher N is the number of widgets
    //Maps all the widget with details from preferences object and widget configs.
    updateWidgets = (preferences = this.props.preferences) => {
        //Get details of each widget from every section
        const widgets = preferences?.Widgets?.WidgetsOrder?.map((section, index) => {
            return getWidgets(section, index, true);
        })

        this.setState({
            widgets
        })
    }

    //θ(1) Toggle isDebounced
    toggleIsDebounced = (isDebounced = !this.state.isDebounced) => {
        this.setState({
            isDebounced
        })
    }

    //θ(1) Checks if we have any api call pending in throttle. If yes it shows an alert to users trying to exit browser
    showAlert = (event) => {
        if(event && this.state.isDebounced) {
            //Flush triggers the pending API calls directly without waiting for the timeout to complete
            this.preferenceThrottler.flush();

            //Prevents user from reloading the tab
            event.preventDefault();
        }
    }

    //θ(1) Returns the widget object which has to be stored into preferences
    getPreferenceWidget = (widget) => {
        return {
            id: widget.id,
            isDisplay: widget.isDisplay,
            isExpand: widget.isExpand
        }
    }

    //Θ(N) where N is the number of widgets in section
    //Change the indexes of the widgets when drag ends
    onDragEnd = (result) => {
        this.setState({
            selectedSection: 0,
            selectedItem: {}
        });

        //Get active and over from result which is an event generated by dnd kit
        const {active, over} = result;
        if(active?.id && over?.id) {
            //Get the section number from data stored in dnd kit.
            //Note: active.data.current is the structure set by dnd kit
            const sectionNumber = active?.data?.current?.section - 1;
            let widgetsOrder = this.state.widgets;

            //Find indexes of widget to be moved and new location
            const oldIndex = widgetsOrder[sectionNumber].findIndex((item) => item.id === active.id);
            const newIndex = widgetsOrder[sectionNumber].findIndex((item) => item.id === over.id);

            widgetsOrder[sectionNumber] = arrayMove(widgetsOrder[sectionNumber], oldIndex, newIndex);
            let preferences = deepClone(this.props.preferences);

            //Update the objects to be able to be stored in preferences
            widgetsOrder = widgetsOrder.map(section => {
                return section.map(widgetDetails => {
                    return this.getPreferenceWidget(widgetDetails);
                })
            });
    
            //Assign updated widgets order
            preferences.Widgets.WidgetsOrder = widgetsOrder;

            this.props.savePreferences(preferences);

            this.updatePreference(preferences);
        }
    }

    //Θ(1) Sets the section number of the widget being moved
    onDragStart = (result) => {
        const selectedSection = result?.active?.data?.current?.section;
        const selectedItem = result?.active?.data?.current?.widget;
        this.setState({
            selectedSection,
            selectedItem
        });
    }

    //Θ(1) Loads users preferences
    loadPreferences = () => {
        getData(
            myPreferences +
                '/' +
                getUser(this.props.user, this.props.impersonation).midas,
            true
        )
        .then(preferences => {
            this.props.savePreferences(preferences);
            this.updateWidgets();
        })
        .catch(err => {
            console.log(err);
        });
    }

    //θ(1) Updates the preferences by making an api call to the backend
    updatePreference = (preferences = this.props.preferences) => {
        const user = getUser(this.props.user, this.props.impersonation);
        // const preferences = this.props.preferences
        putData(myPreferences, {
            preferences,
            midas: user.midas
        })
        .then(_ => {
            this.loadPreferences();
        })
        .catch(err => {
            console.log(err);
        })
        .finally(_ => {
            this.toggleIsDebounced(false);
        })
    }

    //θ(1) Triggers the api throttle
    triggerPreferenceThrottle = () => {
        //To thrigger throttle first set the lock isDebounced as true
        this.toggleIsDebounced(true);

        //Call Preference throttler
        this.preferenceThrottler();
    }

    //Preference Throttler. Takes care of throttling the api calls on being triggered
    preferenceThrottler = debounce(this.updatePreference, WIDGETS_THROTTLE_DELAY, {leading: false});

    render() {
        const user = getUser(this.props.user, this.props.impersonation);

        const isMedicalUser = isUserMedical(user);
        
        if (!Object.keys(this.props.preferences).length) {
            return <SetUp />;
        } else {
            const widgetOrder = this.state.widgets || getDefaultWidgetOrder();
            const user = getUser(this.props.user, this.props.impersonation)
            const isAdmin = isUserAdmin(user);
            const isProd = isProduction();
            return (
                <React.Fragment>
                    {isAdmin && !isProd && <BreakpointBoxes />}
                    <Typography
                        component="h2"
                        className="visually-hidden sr-only"
                    >
                        Dashboard
                    </Typography>
                    <Snackbar
                        open={this.state.selectedSection > 0}
                        message={
                            'Widgets may be dragged and dropped within their column'
                        }
                    />
                    <Container
                        fluid
                        className="myOdu__dashboard maxWidth mt-0 px-2 px-sm-0"
                    >
                        {/* Put marquee / banner / PortalAlert components here */}
                        {/* <EllucianCloudMigration /> */}
                        
                        {
                            this.state?.widgets?.length &&
                            <Row className="pt-2">
                                {Object.values(SectionParams).map((params, idx) => {
                                    return (
                                        <Col
                                            {...params}
                                            key={'dashboardColumn_' + idx}
                                        >
                                            <Section
                                                widgetsOrder={widgetOrder[idx]}
                                                widgets={WidgetConfigs.Widgets[idx]}
                                                sectionNumber={idx + 1}
                                                selectedSection={
                                                    this.state.selectedSection
                                                }
                                                isDroppable={
                                                    Number(
                                                        this.state.selectedSection
                                                    ) > 0 &&
                                                    Number(
                                                        this.state.selectedSection
                                                    ) !==
                                                        idx + 1
                                                }
                                                isDragging={
                                                    this.state.selectedSection > 0
                                                }
                                                selectedItem={
                                                    this.state.selectedItem
                                                }
                                                dragEnd={this.dragEnd}
                                                dragStart={this.dragStart}
                                                onDragEnd = {this.onDragEnd}
                                                onDragStart = {this.onDragStart}
                                                triggerPreferenceThrottle = {this.triggerPreferenceThrottle}
                                                updateWidgets = {this.updateWidgets}
                                            />
                                        </Col>
                                    );
                                })}
                            </Row>
                        }
                    </Container>
                </React.Fragment>
            );
        }
    }
}

const mapStateToProps = state => {
    return {
        preferences: state.preferencesReducer.preferences,
        isOutOfSync: state.preferencesReducer.isOutOfSync,
        user: state.AWSReducer.user,
        isImpersonating:
            state.impersonationReducer.impersonation?.isImpersonating ?? false,
        impersonation: state.impersonationReducer.impersonation
    };
};

const mapDispatchToProps = dispatch => ({
    savePreferences: preferences => dispatch(savePreferences(preferences)),
    saveIsOutOfSync: isOutOfSync => dispatch(saveIsOutOfSync(isOutOfSync))
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
